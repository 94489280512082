import React, {useState , useEffect} from 'react';
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';
import "../Page.css";
import {
    Link,
    useParams
  } from "react-router-dom";

export default function Editcustomer() {

    let { slug } = useParams();

      const [customerName , setCustomerName] = useState("");
      const [membership , setMembership] = useState("");
      const [whatsapp , setWhatsapp] = useState("");
      const [sim1 , setSim1] = useState("");
      const [phone2 , setPhone2] = useState("");
      const [sim2 , setSim2] = useState("");
      const [phone3 , setPhone3] = useState("");
      const [sim3 , setSim3] = useState("");
      const [phone4 , setPhone4] = useState("");
      const [sim4 , setSim4] = useState("");
      const [address , setAddress] = useState("");
      const [maplink , setMaplink] = useState("");
      const [email , setEmail] = useState("");
      const [km , setKm] = useState("");
      const [pincode , setPincode] = useState("");
      const [area , setArea] = useState("");
      const [city , setCity] = useState("");
      const [eb , setEb] = useState("");
      const [tvdisk , setTvdisk] = useState("");

      const [areaData , setAreaData] = useState([]);
      const [cityData , setCityData] = useState([]);
      const [loader , setLoader] = useState(true);
      

         const getCustomerData = async() => {
            try{
               const res = await axios.get(`${baseUrl}/get/getsinglecustomer/${slug}`);
               setCustomerData(res.data);
               setLoader(false);
            }catch(e){
               toast.error("Connection Error");
            }
         }


         const setCustomerData = (data) => {
            setCustomerName(data.name);
            setMembership(data.membership);
            setWhatsapp(data.whatsapp);
            setSim1(data.sim1);
            setPhone2(data.phone2);
            setSim2(data.sim2);
            setPhone3(data.phone3);
            setSim3(data.sim3);
            setPhone4(data.phone4);
            setSim4(data.sim4);
            setAddress(data.address);
            setMaplink(data.maplink);
            setEmail(data.email);
            setKm(data.km);
            setPincode(data.pincode);
            setArea(data.area);
            setCity(data.city);
            setEb(data.eb);
            setTvdisk(data.tvdisk);
         }
   



    const getAreaData = async() => {
      try{
         const res = await axios.get(`${baseUrl}/get/getallarea`);
         setAreaData(res.data);
      }catch(e){
         toast.error("Connection Error");
      }
   }

   const getCityData = async() => {
      try{
         const res = await axios.get(`${baseUrl}/get/getallcity`);
         setCityData(res.data);
      }catch(e){
         toast.error("Connection Error");
      }
   }


      const editCustomer = async(e) => {
            try{
              e.preventDefault();
              const tempData = {
                  name : customerName,
                  membership : membership,
                  whatsapp : whatsapp,
                  sim1: sim1,
                  phone2 : phone2,
                  sim2: sim2,
                  phone3 : phone3,
                  sim3: sim3,
                  phone4 : phone4,
                  sim4: sim4,
                  address : address,
                  maplink : maplink,
                  email : email,
                  km : km,
                  pincode : pincode,
                  area : area,
                  city : city,
                  eb : eb,
                  tvdisk : tvdisk
              };
              

              await toast.promise(
                  axios.put(`${baseUrl}/put/editcustomer/${slug}`,tempData),
                 {
                 pending: 'Loading in Progress',
                 success: 'Customer Detail Edited',
                 error: 'Editing Rejected'
                 }
             )

            }catch(e){
              toast.error("Connection Error")
            }
          }


          useEffect(()=>{
            getAreaData();
         },[])
    
         useEffect(()=>{
            getCityData();
         },[])


         useEffect(()=>{
            getCustomerData();
         },[]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className='contain'>
              <ToastContainer/>
        
            <form onSubmit={editCustomer}>
        <nav className='add-navbar fixed-top'>
        <div className='row justify-content-between align-items-center m-0 py-3'>
        <div className='col-auto'>
        <Link to={`/customer/${slug}`} className="btn btn-sm brand-Btn">Back</Link>
            </div>
            |
            <div className='col'>
                <p className='small m-0' style={{fontWeight : 600}}>Edit Customer Information:</p>
            </div>
            <div className='col-auto'>
            <button type="submit" className="btn btn-sm brand-Btn">Save Customer</button>
            </div>
        </div>
        </nav>
        {
            loader ? 
            <div className="text-center mt-5 pt-5">
                    <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                    </div>
                    <p><small>Loading...</small></p>
            </div>
            :
       <div className='container my-5 py-5'>
       <div className="row justify-content-center">
          <div className="col-12 col-md-7 px-1">
            <h5 className='my-4' style={{fontWeight : 600}}>Customer Information</h5>
            <p className='text-secondary small' style={{fontWeight : 600}}>Customer Information</p>
            <div className='row'>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Name</p>
                  <input className="form-control form-control-sm mb-3" placeholder="Customer Name"
                  onChange={(e)=>setCustomerName(e.target.value)} value={customerName} required/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Membership Type</p>
                  <select className="form-select form-select-sm text-secondary mb-3" value={membership} onChange={(e)=>setMembership(e.target.value)}>
                    <option selected>Select Membership Type</option>
                    <option value="Diamond">Diamond</option>
                    <option value="Gold">Gold</option>
                    <option value="Silver">Silver</option>
                    <option value="Bronze">Bronze</option>
                    </select>
            </div>
            <div className='col-8 col-md-4 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>WhatsApp Number*</p>
                  <input className="form-control form-control-sm mb-3" value={whatsapp}
                  placeholder="Whatsapp Number" onChange={(e)=>setWhatsapp(e.target.value)}/>
            </div>
            <div className='col-4 col-md-2 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Sim Name *</p>
            <select className="form-select form-select-sm text-secondary mb-3" value={sim1}
            onChange={(e)=>setSim1(e.target.value)}>
                    <option value="None">None</option>
                    <option value="Jio">Jio</option>
                    <option value="Airtel">Airtel</option>
                    <option value="BSNL">BSNL</option>
                    <option value="Vi">Vi</option>
                    <option value="Idea">Idea</option>
                    <option value="Other">Others</option>
                    </select>
            </div>
            <div className='col-8 col-md-4 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Person 2 Number</p>
                  <input className="form-control form-control-sm mb-3" value={phone2}
                  placeholder="Person 2 Number" onChange={(e)=>setPhone2(e.target.value)}/>
            </div>
            <div className='col-4 col-md-2 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Sim Name</p>
            <select className="form-select form-select-sm text-secondary mb-3" value={sim2}
            onChange={(e)=>setSim2(e.target.value)}>
                    <option value="None">None</option>
                    <option value="Jio">Jio</option>
                    <option value="Airtel">Airtel</option>
                    <option value="BSNL">BSNL</option>
                    <option value="Vi">Vi</option>
                    <option value="Idea">Idea</option>
                    <option value="Other">Others</option>
                    </select>
            </div>
            <div className='col-8 col-md-4 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Person 3 Number</p>
                  <input className="form-control form-control-sm mb-3" value={phone3}
                  placeholder="Person 3 Number" onChange={(e)=>setPhone3(e.target.value)}/>
            </div>
            <div className='col-4 col-md-2 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Sim Name</p>
            <select className="form-select form-select-sm text-secondary mb-3" value={sim3}
            onChange={(e)=>setSim3(e.target.value)}>
                    <option value="None">None</option>
                    <option value="Jio">Jio</option>
                    <option value="Airtel">Airtel</option>
                    <option value="BSNL">BSNL</option>
                    <option value="Vi">Vi</option>
                    <option value="Idea">Idea</option>
                    <option value="Other">Others</option>
                    </select>
            </div>
            <div className='col-8 col-md-4 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Person 4 Number</p>
                  <input className="form-control form-control-sm mb-3" value={phone4}
                  placeholder="Person 4 Number" onChange={(e)=>setPhone4(e.target.value)}/>
            </div>
            <div className='col-4 col-md-2 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Sim Name</p>
            <select className="form-select form-select-sm text-secondary mb-3" value={sim4}
            onChange={(e)=>setSim4(e.target.value)}>
                    <option value="None">None</option>
                    <option value="Jio">Jio</option>
                    <option value="Airtel">Airtel</option>
                    <option value="BSNL">BSNL</option>
                    <option value="Vi">Vi</option>
                    <option value="Idea">Idea</option>
                    <option value="Other">Others</option>
                    </select>
            </div>
            <div className='col-12 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Address</p>
            <textarea className="form-control form-control-sm mb-3" rows={3} value={address}
            placeholder="Enter Customer's address" onChange={(e)=>setAddress(e.target.value)}></textarea>
            </div>
            <div className='col-12 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Map Link from (What 3 Words App)</p>
                  <input className="form-control form-control-sm mb-3" value={maplink} type="url"
                  placeholder="Map Link" onChange={(e)=>setMaplink(e.target.value)}/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>EB Number</p>
                  <input className="form-control form-control-sm mb-3" value={eb}
                  placeholder="Enter EB number" onChange={(e)=>setEb(e.target.value)}/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>TV Disk Number</p>
                  <input className="form-control form-control-sm mb-3" value={tvdisk}
                  placeholder="Enter TV Disk Number" onChange={(e)=>setTvdisk(e.target.value)}/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Email Address</p>
                  <input className="form-control form-control-sm mb-3" value={email}
                  placeholder="Enter email address" onChange={(e)=>setEmail(e.target.value)}/>
            </div>
            <div className='col-12 col-md-3 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>KM to Home</p>
                  <input className="form-control form-control-sm mb-3" value={km}
                  placeholder="Distance to Home" onChange={(e)=>setKm(e.target.value)}/>
            </div>
            <div className='col-12 col-md-3 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Pincode</p>
                  <input className="form-control form-control-sm mb-3" value={pincode}
                  placeholder="Pincode" onChange={(e)=>setPincode(e.target.value)}/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Area Name</p>
            <select className="form-select form-select-sm text-secondary mb-3" value={area}
            onChange={(e)=>setArea(e.target.value)}>
                    <option selected value="None">Select Area Name</option>
                    {
                          areaData.map((data,i)=>{
                                return(
                                    <option value={data.name} key={i}>{data.name}</option>
                                )
                          })
                    }
                    </select>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>City Name</p>
            <select className="form-select form-select-sm text-secondary mb-3" value={city}
            onChange={(e)=>setCity(e.target.value)}>
                    <option selected value="None">Select City Name</option>
                    {
                          cityData.map((data,i)=>{
                                return(
                                    <option value={data.name} key={i}>{data.name}</option>
                                )
                          })
                    }
                    </select>
            </div>
            {/* <div className='col-12 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Indentity Proof (Aadhar, Pancard, etc)</p>
                  <input className="form-control form-control-sm mb-3" type="file" placeholder="Customer Name"/>
            </div> */}
            </div>
           </div>
        </div>
       </div>
        }
        </form>
    </div>
    )
}
