import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';  
import 'bootstrap/dist/js/bootstrap.bundle.min';  
import 'react-toastify/dist/ReactToastify.css';

import "./App.css";

import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import Notfound from "./Components/Notfound";
import Customer from './Components/Customer';
import Addcustomer from "./Components/Addcustomer";
import Showsupplier from "./Components/Showsupplier";
import Editcustomer from "./Components/Editcustomer";
import Editsupplier from "./Components/Editsupplier";
import Product from './Components/Product';
import Trader from './Components/Supplier';
import Addtrader from './Components/Addtrader';
import Sale from './Components/Sale';
import Report from "./Components/Report";
import Billing from './Components/Billing';
import Setting from './Components/Setting';
import Showcustomer from "./Components/Showcustomer";
import Emibilling from './Components/Emibilling';
import Expense from "./Components/Expense";
import ProtectedRouter from "./Components/Protectedrouter";

function App() {
  return (
    <Router>
       <Switch>
         <Route path="/" exact component={Login}/>
         <ProtectedRouter path="/billing" exact component={Billing}/>
         <ProtectedRouter path="/emibilling" exact component={Emibilling}/>
         <ProtectedRouter path="/dashboard" exact component={Dashboard}/>
         <ProtectedRouter path="/addcustomer" exact component={Addcustomer}/>
         <ProtectedRouter path="/editcustomer/:slug" exact component={Editcustomer}/>
         <ProtectedRouter path="/addsupplier" exact component={Addtrader}/>
         <ProtectedRouter path="/supplier/:slug" exact component={Showsupplier}/>
         <ProtectedRouter path="/editsupplier/:slug" exact component={Editsupplier}/>
         <ProtectedRouter path="/editcustomer" exact component={Editcustomer}/>
         <ProtectedRouter path="/product" exact component={Product}/>
         <ProtectedRouter path="/trader" exact component={Trader}/>
         <ProtectedRouter path="/report" exact component={Report}/>
         <ProtectedRouter path="/expense" exact component={Expense}/>
         <ProtectedRouter path="/sale" exact component={Sale}/>
         <ProtectedRouter path="/customer" exact component={Customer}/>
         <ProtectedRouter path="/customer/:slug" exact component={Showcustomer}/>
         <ProtectedRouter path="/setting" exact component={Setting}/>
         <ProtectedRouter component={Notfound}/>
       </Switch>
     </Router>
  );
}

export default App;
