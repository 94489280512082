import React , {useState , useEffect} from 'react'
import Navbar from './Navbar'
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';

export default function Product() {

  const [productData , setProductData] = useState([]);
  const [filterProduct , setFilterProduct] = useState([]);

  const [productName , setProductName] = useState("");
  const [buyingPrice , setBuyingPrice] = useState("0");
  const [sellingPrice , setSellingPrice] = useState("0");
  const [quantity , setQuantity] = useState("");
  const [intimationRate , setIntimationRate] = useState("");
  const [productCode , setProductCode] = useState("");
  const [barcode , setBarcode] = useState("");
  const [currentProductId , setCurrentProductId] = useState("");

  const [incomingQuantity , setIncomingQuantity] = useState(0);

  const[loader,setLoader] = useState(true);


  // const [viewPassword , setViewPassword] = useState(false);
    // const [currentUserPassword , setCurrentUserPassword] = useState("");

    // const showCred = (e) => {
    //     e.preventDefault();
    //     const userData = JSON.parse(localStorage.getItem("NelvanamAdmin"));
    //     if(userData.password === currentUserPassword){
    //         setViewPassword(true);
    //     }else{
    //         toast.error("Incorrect Password!");
    //     }
    // };


  const getProductData = async() => {
     try{
        const res = await axios.get(`${baseUrl}/get/getallproduct`);
        setProductData(res.data);
        setFilterProduct(res.data);
        setLoader(false);
     }catch(e){
        toast.error("Connection Error");
     }
  }

  const refreshForm = () => {
    setIncomingQuantity(0);
    setCurrentProductId("");
    setProductName("");
    setBuyingPrice("0");
    setSellingPrice("0");
    setQuantity("");
    setIntimationRate("");
    setProductCode("");
    setBarcode("");
    // setCurrentUserPassword("");
    // setViewPassword(false);
  }

  

  const submitNewProduct = async(e) => {
    try{
      e.preventDefault();
      const tempData = {
        name : productName,
        quantity : quantity,
        buyingPrice : buyingPrice,
        sellingPrice : sellingPrice,
        intimationRate : intimationRate,
        productCode : productCode,
        barcode : barcode,
      };

      await toast.promise(
        axios.post(`${baseUrl}/post/newproduct`,tempData),
       {
       pending: 'Loading in Progress',
       success: 'New Product Added',
       error: 'Process Rejected'
       }
   )

        refreshForm();
        getProductData();
    }catch(e){
      toast.error("Connection Error")
    }
  }

  const updateStock = async(e) => {
    try{
      e.preventDefault();
      const tempData = {
        quantity : incomingQuantity,
      };

      await toast.promise(
        axios.put(`${baseUrl}/put/updatequantity/${currentProductId}`,tempData),
       {
       pending: 'Loading in Progress',
       success: 'Stock Updated',
       error: 'Process Rejected'
       }
   )
       setIncomingQuantity(0);
       refreshForm();
        getProductData();
    }catch(e){
      toast.error("Connection Error")
    }
  }

  const submitEditProduct = async(e) => {
    try{
      e.preventDefault();
      const tempData = {
        name : productName,
        quantity : quantity,
        buyingPrice : buyingPrice,
        sellingPrice : sellingPrice,
        intimationRate : intimationRate,
        productCode : productCode,
        barcode : barcode,
      };

      await toast.promise(
        axios.put(`${baseUrl}/put/editproduct/${currentProductId}`,tempData),
       {
       pending: 'Loading in Progress',
       success: 'Product Edited',
       error: 'Process Rejected'
       }
   )
        refreshForm();
        getProductData();
    }catch(e){
      toast.error("Connection Error")
    }
  }

  const deleteProduct = async(userData) => {
    try{

      await toast.promise(
        axios.delete(`${baseUrl}/delete/deleteproduct/${userData}`),
       {
       pending: 'Loading in Progress',
       success: 'Product Deleted',
       error: 'Process Rejected'
       }
   )
          refreshForm();
            getProductData();
     }catch(e){
        toast.error("Connection Error");
     }
 }

  const settCurrentProduct = (data) => {
    setCurrentProductId(data._id);
    setProductName(data.name);
    setBuyingPrice(data.buyingPrice);
    setSellingPrice(data.sellingPrice);
    setQuantity(data.quantity);
    setIntimationRate(data.intimationRate);
    setProductCode(data.productCode);
    setBarcode(data.barcode);
  }


  const searchProduct = (val) => {
    const filteredData = productData.filter(i=>i.name.toLowerCase().includes(val.toLowerCase()) ||
                                               i.productCode.toLowerCase().includes(val.toLowerCase()));
     setFilterProduct(filteredData);
 }

 
  const preventMinus = (e) => {
    if((e.target.value).includes("-")){
      setIncomingQuantity(Math.abs(e.target.value))
    }
  };

  useEffect(()=>{
    getProductData();
  },[])

    return (
        <>
        <ToastContainer/>
            <div className='mt-4 py-4'>
        <Navbar/>
        <div className='row justify-content-between align-items-center mt-4 p-0'>
            <div className='col-auto'>
                <h4 style={{fontWeight : 600,margin : "auto"}}>Products - {productData.length}</h4>
            </div>
            <div className='col-auto'>
                  <button type="button" className="btn btn-sm brand-Btn" data-bs-toggle="modal" data-bs-target="#addproduct">
                  Add New Product
                  </button>
            </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-2 px-1">
          <div className='card stat-card'>
                <div className='card-body p-2'>
                 <input className="form-control form-control-sm mb-2" onChange={(e)=>searchProduct(e.target.value)} type="search" placeholder="Search Product" aria-label="Search"/>
                 <p className='px-2'><small>Use Product Name to search.</small></p>
                 </div>
          </div>       
          </div>
          <div className="col-12 col-md-10 px-1">
            <div className='card stat-card'>
                <div className='card-body'>
                    {
                      loader ? 
                        <div className="text-center">
                            <div className="spinner-border spinner-border-sm" role="status">
                                      <span className="visually-hidden">Loading...</span>
                            </div>
                            <p><small>Loading...</small></p>
                        </div>
                        :
                      <div className="table-responsive">
                      <table className="table table-borderless table-sm">
                          <thead className='borderbottom'>
                            <tr>
                              <td className="table-title">PRODUCT</td>
                              <td className="table-title">QUANTITY</td>
                              <td className="table-title">PRICE</td>
                              <td className="table-title">CODE</td>
                              <td className="table-title">BARCODE</td>
                              <td className="table-title">UPDATE</td>
                              <td className="table-title">ACTION</td>
                            </tr>
                          </thead>
                          <tbody>
                          {
                                  filterProduct.map((data,i)=>{
                                    return(
                                      <tr key={i}>
                                        <td className='table-data'>{data.name}</td>
                                        <td className='table-data'>{data.quantity}</td>
                                        <td className='table-data'>₹ {data.sellingPrice}</td>
                                        <td className='table-data'>{data.productCode}</td>
                                        <td className='table-data'>{data.barcode}</td>
                                        <td className='table-data'>
                                        <button className='showBillBtn' data-bs-toggle="modal" data-bs-target="#updatestock" onClick={()=>settCurrentProduct(data)}>
                                                      Update Stock
                                          </button>
                                        </td>
                                        <td className='table-data'>
                                        <button className='showBillBtn' data-bs-toggle="modal" data-bs-target="#editproduct" onClick={()=>settCurrentProduct(data)}>
                                                      Edit Product
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                          </tbody>
                        </table>
                      </div>
                      }
                </div>
            </div>
          </div>
        </div>
    </div>
    <div className="modal fade" id="editproduct" data-bs-backdrop="static" aria-labelledby="editproduct" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      
      <div className="modal-content">
        <div className="modal-header">
          <p className="modal-title" id="editproduct">Edit Product Information</p>
        </div>
        <div className="modal-body">
        {/* {
                  !viewPassword
                  ?
                  <form onSubmit={showCred}>
                    <input className="form-control form-control-sm my-2" type="password" value={currentUserPassword} required onChange={(e)=>setCurrentUserPassword(e.target.value)} placeholder="Enter your password!"/>
                    <button type="button" className="btn btn-sm btnClose" data-bs-dismiss="modal" onClick={refreshForm}>Cancel</button>&nbsp;
                    <button type='submit' className="btn btn-sm brand-Btn">Continue</button>
                  </form>
                  : */}
            <form onSubmit={submitEditProduct}>
              <div className='row'>
              <div className='col-12 px-1'>
                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Product Name</p>
                      <input className="form-control form-control-sm mb-3"
                      placeholder="Enter product name" maxLength={30} 
                      value={productName} onChange={(e)=>setProductName(e.target.value)} required/>
                </div>
                <div className='col-12 col-md-6 px-1'>
                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Buying Price</p>
                      <input className="form-control form-control-sm mb-3" placeholder="Enter Buying price" value={buyingPrice} onChange={(e)=>setBuyingPrice(e.target.value)}/>
                </div>
                <div className='col-12 col-md-6 px-1'>
                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Selling Price</p>
                      <input className="form-control form-control-sm mb-3" 
                      placeholder="Enter selling price" 
                      value={sellingPrice} onChange={(e)=>setSellingPrice(e.target.value)} required/>
                </div>
                <div className='col-12 col-md-5 px-1'>
                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Quantity</p>
                      <input className="form-control form-control-sm mb-3" placeholder="Enter quantity" 
                      value={quantity} onChange={(e)=>setQuantity(e.target.value)}/>
                </div>
                <div className='col-12 col-md-7 px-1'>
                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Low Stock Intimation rate (eg.10)</p>
                      <input className="form-control form-control-sm mb-3" placeholder="Enter Limit rate" 
                      value={intimationRate} onChange={(e)=>setIntimationRate(e.target.value)}/>
                </div>
                <div className='col-12 px-1'>
                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Product Code (eg: 1)</p>
                      <input className="form-control form-control-sm mb-3" placeholder="Enter Product Code" 
                      value={productCode} onChange={(e)=>setProductCode(e.target.value)}/>
                </div>
                <div className='col-12 px-1'>
                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Barcode Number</p>
                      <input className="form-control form-control-sm mb-3" placeholder="Enter Barcode Number" 
                      value={barcode} onChange={(e)=>setBarcode(e.target.value)}/>
                </div>
                <div className="d-flex">
                  <button type="button" className="btn btn-sm btn-danger me-auto" onClick={()=>deleteProduct(currentProductId)} data-bs-dismiss="modal">Delete Product</button>&nbsp;
                  <button type="button" className="btn btn-sm btnClose" data-bs-dismiss="modal" onClick={refreshForm}>Cancel</button>&nbsp;
                  <button type="submit" className="btn btn-sm brand-Btn" data-bs-dismiss="modal">Save</button>
                </div>
              </div>
              </form>
                {/* } */}
        </div>
      </div>   
    </div>
  </div>

  <div className="modal fade" id="updatestock" data-bs-backdrop="static" aria-labelledby="updateLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
     <div className="modal-content">
        <div className="modal-header">
          <p className="modal-title" id="updateLabel">Update Stock Details</p>
        </div>
        <div className="modal-body">
                  <div className='row'>
                  <div className='col-12 px-1 username mb-4 ps-4'>
                    <p className='small m-0 mb-1' style={{fontWeight : 600}}>Exciting Quantity: <b>{quantity}</b></p>
                    <p className='small m-0 mb-1' style={{fontWeight : 600}}>Incoming Quantity: <b>{incomingQuantity}</b></p>
                    <p className='small m-0 mb-1' style={{fontWeight : 600}}>Total Quantity: &nbsp; <b>{parseInt(quantity) + parseInt(incomingQuantity)}</b></p>
                    </div>
                  <div className='col-12 px-1'>
                    <form onSubmit={updateStock}>
                        <input 
                        className="form-control form-control-sm mb-3" 
                        value={incomingQuantity}
                        onChange={(e)=>setIncomingQuantity(e.target.value === "" || e.target.value === "-" ? 0 :e.target.value)} 
                        type="number"
                        min={0}
                        onKeyUp={preventMinus}
                        onPaste={(e)=>{
                          e.preventDefault()
                          return false;
                        }} 
                        placeholder="Enter quantity"/>
                      <div className="d-flex">
                        <button type="button" className="btn btn-sm btnClose" onClick={refreshForm} data-bs-dismiss="modal">Close</button>&nbsp;
                        <button type="submit" className="btn btn-sm brand-Btn">SAVE</button>
                      </div>
                    </form>
                    </div>
                  </div>
        </div>
      </div>
    </div>
  </div>

  <div className="modal fade" id="addproduct" data-bs-backdrop="static" aria-labelledby="addproductLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      
      <div className="modal-content">
        <div className="modal-header">
          <p className="modal-title" id="addproductLabel">Add Product Information</p>
        </div>
        <div className="modal-body">
        {/* {
                  !viewPassword
                  ?
                  <form onSubmit={showCred}>
                    <input className="form-control form-control-sm my-2" type="password" value={currentUserPassword} required onChange={(e)=>setCurrentUserPassword(e.target.value)} placeholder="Enter your password!"/>
                    <button type="button" className="btn btn-sm btnClose" data-bs-dismiss="modal" onClick={refreshForm}>Cancel</button>&nbsp;
                    <button type='submit' className="btn btn-sm brand-Btn">Continue</button>
                  </form>
                  : */}
                      <form onSubmit={submitNewProduct}>
                    <div className='row m-0'>
                          <div className='col-12 px-1'>
                            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Product Name</p>
                                  <input className="form-control form-control-sm mb-3"
                                  placeholder="Enter product name" maxLength={30} 
                                  value={productName} onChange={(e)=>setProductName(e.target.value)} required/>
                            </div>
                            <div className='col-12 col-md-6 px-1'>
                            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Buying Price</p>
                                  <input className="form-control form-control-sm mb-3" placeholder="Enter Buying price" value={buyingPrice} onChange={(e)=>setBuyingPrice(e.target.value)}/>
                            </div>
                            <div className='col-12 col-md-6 px-1'>
                            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Selling Price</p>
                                  <input className="form-control form-control-sm mb-3" 
                                  placeholder="Enter selling price" 
                                  value={sellingPrice} onChange={(e)=>setSellingPrice(e.target.value)}/>
                            </div>
                            <div className='col-12 col-md-5 px-1'>
                            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Quantity</p>
                                  <input className="form-control form-control-sm mb-3" placeholder="Enter quantity" 
                                  value={quantity} onChange={(e)=>setQuantity(e.target.value)}/>
                            </div>
                            <div className='col-12 col-md-7 px-1'>
                            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Low Stock Intimation rate (eg.10)</p>
                                  <input className="form-control form-control-sm mb-3" placeholder="Enter Limit rate" 
                                  value={intimationRate} onChange={(e)=>setIntimationRate(e.target.value)}/>
                            </div>
                            <div className='col-12 px-1'>
                            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Product Code (eg: 1)</p>
                                  <input className="form-control form-control-sm mb-3" placeholder="Enter Product Code" 
                                  value={productCode} onChange={(e)=>setProductCode(e.target.value)}/>
                            </div>
                            <div className='col-12 px-1'>
                            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Barcode Number</p>
                                  <input className="form-control form-control-sm mb-3" placeholder="Enter Barcode Number" 
                                  value={barcode} onChange={(e)=>setBarcode(e.target.value)}/>
                            </div>
                          <div className="d-flex">
                            <button type="button" className="btn btn-sm btnClose" data-bs-dismiss="modal">Cancel</button>&nbsp;
                            <button type="submit" className="btn btn-sm brand-Btn" data-bs-dismiss="modal">Add Product</button>
                          </div>
                    </div>
                      </form>
            {/* } */}
        </div>
      </div>
    </div>
  </div>
        </>
    )
}
