import React , {useEffect ,useState , useRef} from 'react'
import Navbar from './Navbar'
import { useParams , Link } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import axios from "axios";
import baseUrl from '../utils/Url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToastContainer, toast } from 'react-toastify';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export default function Showcustomer() {

    let { slug } = useParams();
    const componentRef = useRef();

    const [userData , setUserData] = useState({});
    const [loader , setLoader] = useState(true);

    // const [loyaltyPoint , setLoyaltyPoint] = useState(0)
    
    
    const [selectedSale , setSelectedSale] = useState({});
    
    const getCustomerData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getsinglecustomer/${slug}`);
           setUserData(res.data);
           setLoader(false);
        }catch(e){
           toast.error("Connection Error");
        }
     }


    //  const updateloyaltypoint = async() => {
    //     try{
    //         const tempData = {
    //             loyaltyPoint : loyaltyPoint
    //         }

    //         await toast.promise(
    //             axios.put(`${baseUrl}/put/updateloyaltypoint/${slug}`,tempData),
    //            {
    //            pending: 'Loading in Progress',
    //            success: 'Loyalty Point Updated',
    //            error: 'Process Rejected'
    //            }
    //        )
            
    //             getCustomerData();
    //     }catch(e){
    //         toast.error("Connection Error")
    //     }
    //  }

    

     useEffect(()=>{
        getCustomerData();
     },[]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
        <ToastContainer/>
        <Navbar/>
            <div className='mt-4 py-4'>
        {
            loader ?
            <div className="text-center">
                  <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                   </div>
                   <p><small>Loading...</small></p>
           </div>
            :
            <div className="row mt-4">
             <div className="col-12 col-md-3">
                <div className='card'>
                    <div className='card-body'>
                    <h4 className='fw-bold text-secondary'>{userData.name}</h4>
                 <p className='px-2 gold'>{userData.membership}</p>
                 <div className='row mx-0'>
                    <div className='col-3 px-0'>
                    <p className='m-0 table-data'>Points</p>
                    <p className='m-0'>{userData.loyaltyPoint}</p>
                    </div>  
                    <div className='col-5 px-0'>
                    <p className='m-0 table-data'>Since</p>
                    <p className='m-0 small'>{new Date(userData.createdAt).toDateString()}</p>
                    </div>
                    <div className='col-4 px-0'>
                    <p className='m-0 table-data'>Balance</p>
                    <p className='m-0 text-danger'>₹ {userData.balance}</p>
                    </div>
                 </div>
                        <Link to={`/editcustomer/${userData._id}`}>
                            <button className="showBillBtn">
                            Edit Customer
                            </button>
                        </Link>
                            {/* <button className='showBillBtn' type='button' data-bs-toggle="modal" data-bs-target="#updateloyaltypoint">
                            Update loyaltyPoint
                              </button> */}
                     <h6 className='fw-bold px-0 mt-4'>Customer Details</h6>
                            <div className='row mt-3 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title text-success'><FontAwesomeIcon icon={faWhatsapp} /> What'sapp No:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.whatsapp}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Phone Number 2:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.phone2}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Phone Number 3:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.phone3}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Phone Number 4:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.phone4}</p>
                                </div>
                            </div>
                            <div className='row mt-3 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>TV Disk Number:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.tvdisk}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Address:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.address}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Map Link:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.maplink}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Email:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.email}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Km for Home:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.km}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Pincode:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.pincode}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Area Name:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.area}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>City:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.city}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>EB Number:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.eb}</p>
                                </div>
                            </div>
                    </div>
                </div>  
                </div>
                        <div className="col-12 col-md-6">
                            <div className='card'>
                                <div className='card-body p-0 saleList'>

                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link text-black" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Sale Histroy</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link text-black" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Emi Paid Histroy</button>
                                    </li>
                                    </ul>


                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className='row justify-content-between align-items-center m-0 py-2 borderbottom'>
                                                    <div className='col-4'>
                                                        <p className='table-title m-0'>Date</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <p className='table-title m-0'>Total</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <p className='table-title m-0'>Paid</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <p className='table-title m-0'>Balance</p>
                                                    </div>
                                                    <div className='col-2 text-center'>
                                                        <p className='table-title m-0'>Bill</p>
                                                    </div>
                                            </div>
                                    {
                                        userData.sales.length <= 0 ?
                                            <p className='text-center small text-secondary mt-5'>No Sales Record</p>
                                        :
                                        userData.sales.map((data,i) => {
                                                return(
                                                    <div className='row justify-content-between align-items-center m-0 my-1 p-0' key={i}>
                                            <div className='col-4'>
                                                <p className='table-data m-0'>{new Date(data.createdAt).toDateString()}</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='table-title m-0'>₹ {data.total}</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='table-data m-0'>₹ {data.paid}</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='balance m-0'>₹ {data.balance}</p>
                                            </div>
                                            <div className='col-2 text-center'>
                                                <button className='showBillBtn' onClick={()=>setSelectedSale(data)}>
                                                    Show Bill
                                                </button>
                                            </div>
                                            </div>
                                                )
                                        })
                                    }

                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className='row justify-content-between align-items-center m-0 py-2 borderbottom'>
                                                    <div className='col-4'>
                                                        <p className='table-title m-0'>Date</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <p className='table-title m-0'>Tim</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <p className='table-title m-0'>Pending</p>
                                                    </div>
                                                    <div className='col-2'>
                                                        <p className='table-title m-0'>Paid</p>
                                                    </div>
                                                    <div className='col-2 text-center'>
                                                        <p className='table-title m-0'>Balance</p>
                                                    </div>
                                            </div>
                                    {
                                        userData.emis.length <= 0 ?
                                            <p className='text-center small text-secondary mt-5'>No Emi Record</p>
                                        :
                                        userData.emis.map((data,i) => {
                                                return(
                                                    <div className='row justify-content-between align-items-center m-0 my-1 p-0' key={i}>
                                            <div className='col-4'>
                                                <p className='table-data m-0'>{new Date(data.createdAt).toDateString()}</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='table-data m-0'>{data.time}</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='table-title m-0'>₹ {data.pendingAmount}</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='table-data m-0'>₹ {data.payingAmount}</p>
                                            </div>
                                            <div className='col-2 text-center'>
                                                <p className='balance m-0'>₹ {data.balance}</p>
                                            </div>
                                            </div>
                                                )
                                        })
                                    }

                                        </div>
                                    </div>


                             
                </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
          <div className='d-grid gap-2 mb-3'>
              {
                  !selectedSale.customerName ? 
                  <p className='table-title text-center'>No Bill Selected</p>
                  :
                  <ReactToPrint
                                trigger={() => <button className='btn brand-Btn btn-sm'>
                                                    Print Below Bill
                                                </button>}
                                content={() => componentRef.current}
                           />
                  
              }
          
          </div>
              <div className='card'>
                    <div className='card-body p-0 billList'>
                    {
                        !selectedSale.customerName ? 
                            <p className='table-title text-center mt-3'>No Bill Selected</p>
                            :
                            <div className='inner-bg m-0 p-0' ref={componentRef}>
                    <div className="inner-border pb-3">
                    <div className='row'>
                            <div className='col-12 text-center mb-3'>
                                <img src={process.env.PUBLIC_URL + '/logo.png'} className='img-fluid bill-img' width={170} alt='' />
                                <p className='m-0 small billText'>Sri Amman Complex, GH Stop,</p>
                                <p className='m-0 small billText'> Athani Road, Sathyamangalam - 638401</p>
                                <p className='m-0 small billText'>www.nelvanam.com</p>
                                <p className='m-0 small billText'>Ph - +91 8903072643</p>
                            </div>
                            <div className='col-12 text-start'>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-auto px-0'>
                                        <p className='m-0 small billText'>Customer: {selectedSale.customerName}</p>
                                        <p className='m-0 small billText'>Order No: {selectedSale.orderNo}</p>
                                    </div>
                                    <div className='col-auto px-0'>
                                        <p className='m-0 small billText'>Date: {selectedSale.date}</p>
                                        <p className='m-0 small billText'>Time: {selectedSale.time}</p>
                                    </div>
                                </div>
                                 <div className="mt-2 mb-1 hr-bill"></div>
                                 <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>ITEMS</p>
                                    </div>
                                    <div className='col-2 px-0 text-center'>
                                        <p className='m-0 small billText'>RATE</p>
                                    </div>
                                    <div className='col-2 px-0 text-center'>
                                        <p className='m-0 small billText'>QTY</p>
                                    </div>
                                    <div className='col-2 px-0 text-end'>
                                        <p className='m-0 small billText'>NET</p>
                                    </div>
                                </div>
                                <div className="mt-1 mb-2 hr-bill"></div>

                                {
                                    
                                    selectedSale.products.map((data,i)=>{
                                        return(
                                            <div className='row justify-content-between m-0' key={i}>
                                                <div className='col-6 px-0'>
                                                    <p className='m-0 small billText'>{data.name}</p>
                                                </div>
                                                <div className='col-2 px-0 text-center'>
                                                    <p className='m-0 small billText'>₹ {data.rate}</p>
                                                </div>
                                                <div className='col-2 px-0 text-center'>
                                                    <p className='m-0 small billText'>{data.qty}</p>
                                                </div>
                                                <div className='col-2 px-0 text-end'>
                                                    <p className='m-0 small billText'>₹ {data.net}</p>
                                                </div>
                                            </div>
                                        )
                                    })    
                                }

                                <div className="mt-3 mb-1 hr-bill"></div>
                                 <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>SUBTOTAL</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {selectedSale.subtotal}</p>
                                    </div>
                                </div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>DISCOUNT</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {selectedSale.discount}</p>
                                    </div>
                                </div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>EXTRA CHARGE</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {selectedSale.charge}</p>
                                    </div>
                                </div>
                                <div className="mt-1 mb-2 hr-bill"></div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billTextTotal'>TOTAL</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billTextTotal'>₹ {selectedSale.total}</p>
                                    </div>
                                </div>
                                <div className="mt-2 mb-3 hr-bill"></div>
                                <div className='row justify-content-between m-0 mb-2'>
                                    <div className='col-6 px-0'>
                                    <p className='m-0 loyaltyPoints'>TOTAL ITEMS : {selectedSale.products.length}</p>
                                    </div>
                                </div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>PAID AMOUNT</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {selectedSale.paid}</p>
                                    </div>
                                </div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>Balance</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {selectedSale.total - selectedSale.paid}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 text-start my-2'>
                                <p className='m-0 small loyaltyPoints'>Loyalty Points : {selectedSale.loyaltyPoint} Points</p>
                            </div>
                            <div className='col-12 text-start mb-3'>
                                <p className='m-0 small term'>TERMS &amp; CONDITIONS</p>
                                <p className='m-0 mt-1 small term'>1. Goods sold cannot be returned or exchanged after long time.</p>
                                <p className='m-0 small term'>2. Any Complaint on product, the return is eligible</p>
                            </div>
                            <div className='col-12 text-center my-2'>
                                <p className='m-0 small billText'>***** Thank You Visit Again *****</p>
                            </div>
                    </div>
                    </div>
              </div>
                        
                    }
                    </div>
              </div>
          
          </div>
        </div>
        }
        
    </div>
    {/* <div className="modal fade" id="updateloyaltypoint" data-bs-backdrop="static" aria-labelledby="updateLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
     <div className="modal-content">
        <div className="modal-header">
          <p className="modal-title" id="updateLabel">Update Loyalpoints</p>
        </div>
        <div className="modal-body">
          <div className='row'>
          <div className='col-12 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Total Points: <b>{userData.loyaltyPoint}</b></p>
            </div>
          <div className='col-12 px-1'>
                  <input className="form-control form-control-sm mb-3" 
                  defaultValue={0}
                  value={loyaltyPoint} onChange={(e)=>setLoyaltyPoint(e.target.value)} 
                  placeholder="Enter quantity"/>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-sm btnClose" data-bs-dismiss="modal">Cancel</button>
          <button type="submit" className="btn btn-sm brand-Btn" onClick={updateloyaltypoint} data-bs-dismiss="modal">SAVE</button>
        </div>
      </div>
    </div>
  </div> */}
        </>
    )
}
