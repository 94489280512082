import React, { useEffect, useState } from 'react'
import {Redirect, Route} from 'react-router-dom';
import baseUrl from '../utils/Url';
import axios from "axios";

//ui

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'


function ProtectedRouter({component: Component,   ...rest}) {

    const [isLoaded , setIsLoaded] = useState(false)
    const [isAuth , setIsAuth] = useState(false)
    const [data, setData] = useState(null)
    const [backdrop , setBackdrop] = useState(true)

    useEffect(()=>{
        getCurrentUser();
    },[])

    
     const getCurrentUser = async ()=>{
        try{

             const res = await axios.get(`${baseUrl}/checkauth`,{
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem("Token-Nelvanam"),
                }
             });

             const userData = localStorage.getItem("Token-Nelvanam");

                if ((res.data.status === 'ok') && userData) {
                    setData(res.data)
                    setIsAuth(true)
                    setIsLoaded(true)
                    setBackdrop(false)
                } else {
                    localStorage.removeItem("Token-Nelvanam");
                    window.location.replace("/");
                }

        }catch(e){
            console.log(e);  
        } 
      }
       
    return (
       <>
       {
           isLoaded?
       <Route 
       {...rest}
       render={(props) => isAuth ?  <Component data={data} {...props}  />  : 
       <Redirect to ={{pathname : '/' , state:{from:props.location}}} /> } /> 
       : 
       <Backdrop className="backdrop" open={backdrop}>
          <CircularProgress color="inherit" />
       </Backdrop>
       }
       </>
    )

  
}

export default ProtectedRouter