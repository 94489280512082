import React , {useEffect , useState} from 'react'
import Navbar from './Navbar';
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';

import {
    Link,
  } from "react-router-dom";

export default function Sale() {

    const [saleData , setSaleData] = useState([]);
    const [filterSale , setFilterSale] = useState([]);
    const [loader,setLoader] = useState(true);


    const getSaleData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallsale`);
           setSaleData(res.data);
           setFilterSale(res.data);
           setLoader(false);
        }catch(e){
           toast.error("Connection Error");
        }
     }


     const searchSale = (val) => {
        const filteredData = saleData.filter(i=>i.customerName.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.orderNo.toLowerCase().includes(val.toLowerCase()));
         setFilterSale(filteredData);
     }


     useEffect(()=>{
        getSaleData();
     },[])

    return (
        <>
        <ToastContainer/>
        <Navbar/>
            <div className='mt-4 py-4'>
        <div className='row justify-content-between align-items-center mt-4 p-0'>
            <div className='col-auto'>
                <h4 style={{fontWeight : 600,margin : "auto"}}>All Sale's - {saleData.length}</h4>
            </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-2 px-1">
                <div className='card stat-card'>
                  <div className='card-body px-2'>
                    <input className="form-control form-control-sm mb-2" onChange={(e)=>searchSale(e.target.value)} type="search" placeholder="Search Sale" aria-label="Search"/>
                  <p className='px-2'><small>Use Customer Name or OrderId to search.</small></p>
                  </div>
                </div>
          </div>
          <div className="col-12 col-md-10 px-1">
            <div className='card stat-card'>
              <div className='card-body'>
                  {
                  loader ? 
                    <div className="text-center">
                        <div className="spinner-border spinner-border-sm" role="status">
                                  <span className="visually-hidden">Loading...</span>
                        </div>
                        <p><small>Loading...</small></p>
                    </div>
                    :
                    <div className="table-responsive">
                    <table className="table table-borderless table-sm">
                        <thead className='borderbottom'>
                          <tr>
                            <td className="table-title">Customer</td>
                            <td className="table-title">Order.No</td>
                            <td className="table-title">Total</td>
                            <td className='table-title'>Paid</td>
                            <td className="table-title">Balance</td>
                            <td className="table-title">Sale.Date</td>
                            <td className="table-title">Action</td>
                          </tr>
                        </thead>
                        <tbody>
                        {
                                filterSale.map((data,i)=>{
                                  return(
                                    <tr className='borderbottom p-0' key={i}>
                                      <td className='table-data'>{data.customerName}</td>
                                      <td className='table-data'>{data.orderNo}</td>
                                      <td className='table-data'>₹ {data.total}</td>
                                      <td className='table-data'>₹ {data.paid}</td>
                                      <td className='balance'>₹ {data.balance}</td>
                                      <td className='table-data'>{new Date(data.createdAt).toDateString()}</td>
                                      <td className='table-data'>
                                      <Link to={`/customer/${data.customerId}`}>
                                              <button className='showBillBtn'>
                                                      Show Customer 
                                          </button>
                                          </Link>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                        </tbody>
                      </table>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
    </div>
        </>
    )
}
