import React , {useEffect , useState, useRef} from 'react'
import Navbar from './Navbar';
import ReactToPrint from 'react-to-print';
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';

import { useReactToPrint } from 'react-to-print';

export default function Billing() {

    const componentRef = useRef();

    const [payingAmount , setPayingAmount] = useState(0);
    const [discount , setDiscount] = useState(0);
    const [charge , setCharge] = useState(0);
    const [subtotal , setsubTotal] = useState(0);
    const [grandTotal , setGrandTotal] = useState(0);
    const [loyaltyPoint , setLoyaltyPoint] = useState(0);
    const [cart , setCart] = useState([]);
    const [filteredProduct , setFilteredProduct] = useState([]);
    const [filteredCustomer , setFilteredCustomer] = useState([]);

    const [selectedCustomer , setSelectedCustomer] = useState({});

    const [showPointCancel , setShowPointCancel] = useState(false);
    
    const [customerData , setCustomerData] = useState([]);
    const [productData , setProductData] = useState([]);

    const [allowLoyaltyPoint,setAllowLoyaltyPoint] = useState(false);

    const [currentQuantity , setCurrentQuantity] = useState(1);

    
    const tempTime = new Date()
    const date = tempTime.getDate() + "-" + (tempTime.getMonth() + 1) + "-" + tempTime.getFullYear();
    const time =  ((tempTime.getHours() === 0) ?
                    12 : 
                    (tempTime.getHours()) > 12 ? 
                    (tempTime.getHours()-12) : 
                    (tempTime.getHours()))
                    + " : " + 
                    tempTime.getMinutes()
                    + " " + 
                    ((tempTime.getHours() < 12 && tempTime.getHours() >= 0) ? "AM" : "PM")
   
    const [orderNo , setOrderNo] = useState("");

    const generateOrderNo = () => {
        const tempOrderNo = tempTime.getDate() +"" + (tempTime.getMonth() + 1)  +"" + tempTime.getMinutes() +"" + tempTime.getHours() +"" + tempTime.getSeconds();
        setOrderNo(tempOrderNo);
    }


    const getProductData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallproduct`);
           setProductData(res.data);
        }catch(e){
           toast.error("Connection Error");
        }
     }

     const getCustomerData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallcustomer`);
           setCustomerData(res.data);
        }catch(e){
           toast.error("Connection Error");
        }
     }

    const pushToCart = (data,qty) => {
        const tempData = {
            name : data.name,
            productId : data._id,
            qty : qty,
            rate : data.sellingPrice,
            net : (qty * data.sellingPrice) 
        }

        const tempSubTotal = subtotal + tempData.net;
        setsubTotal(subtotal + tempData.net)
        calculateTotal(tempSubTotal)
        setCurrentQuantity(1);
        setCart([...cart,tempData]);
    }


    const calculateDiscount = (e) => {
        if(!(e.target.value)){
                setDiscount(0);
                const ans = ((parseInt(subtotal) + parseInt(charge)) - parseInt(0));
                setGrandTotal(ans)
                setPayingAmount(ans)
                calculateLoyaltyPoint(ans)
        }else{
            setDiscount(e.target.value);
            const ans = ((parseInt(subtotal) + parseInt(charge)) - parseInt(e.target.value));
            setGrandTotal(ans)
            setPayingAmount(ans)
            calculateLoyaltyPoint(ans)
        }
        
    }

    const calculateCharge = (e) => {
        if(!(e.target.value)){
                    setCharge(0);
                    const ans = ((parseInt(subtotal) + parseInt(0)) - parseInt(discount));
                    setGrandTotal(ans)
                    setPayingAmount(ans)
                    calculateLoyaltyPoint(ans)
            }else{
                setCharge(e.target.value);
                const ans = ((parseInt(subtotal) + parseInt(e.target.value)) - parseInt(discount));
                setGrandTotal(ans) 
                setPayingAmount(ans)
                calculateLoyaltyPoint(ans) 
            }
    }

    const calculateLoyaltyPoint = (data) => {
        // data = 400 / 100  * 3 = 12
        const temp = Math.floor((data / 100) * 0.8);
        setLoyaltyPoint(temp);
    }

    const deleteItem = (i,data) => {
        const tempArr = cart;
        setCart([]);
        tempArr.splice(i,1);
        const tempSubTotal = subtotal - data.net;
        setsubTotal(subtotal - data.net)
        calculateTotal(tempSubTotal)
        setCart([...tempArr]);
    }


    const calculateTotal = (data) => {
        const ans = ((parseInt(data) + parseInt(charge)) - parseInt(discount));
        setGrandTotal(ans)
        setPayingAmount(ans)
        calculateLoyaltyPoint(ans)
    }   


    const useLoyaltyPoint = () => {
        setShowPointCancel(!showPointCancel);
        const cal = !showPointCancel ?
                    (parseInt(grandTotal) - parseInt(selectedCustomer.loyaltyPoint))
                    :
                    (parseInt(grandTotal) + parseInt(selectedCustomer.loyaltyPoint));;
        setGrandTotal(cal)
        setPayingAmount(cal)
    }
   

    const searchProduct = (val) => {
       const filteredData = productData.filter(i=>i.name.toLowerCase().includes(val.toLowerCase()) || 
                                                  i.productCode.toLowerCase().includes(val.toLowerCase()) || 
                                                  i.barcode.toLowerCase().includes(val.toLowerCase()));
       if(val.length === 0){
        setFilteredProduct([])
       }else{
        setFilteredProduct(filteredData)
       }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });


    const submitSale = async() => {
        try{
            const tempData = {
                customerName : selectedCustomer.name ? selectedCustomer.name : "Non-Regular Customer",
                customerId : selectedCustomer._id,
                orderNo : orderNo,
                products : cart,
                subtotal : subtotal,
                total : grandTotal,
                paid : payingAmount,
                balance : (grandTotal - payingAmount),
                discount : discount,
                charge : charge,
                date : date,
                time : time,
                loyaltyPoint : !allowLoyaltyPoint ? loyaltyPoint : 0,
                usedLoyaltyPoint : showPointCancel ? (selectedCustomer.loyaltyPoint) : 0
            }

            await toast.promise(
                axios.post(`${baseUrl}/post/newsale`,tempData),
               {
               pending: 'Loading in Progress',
               success: 'Billing Finished',
               error: 'Billing Rejected'
               }
           )

                handlePrint();
        }catch(e){
            toast.error("Connection Error");
        }
    }

    const searchCustomer = (val) => {
        const filteredData = customerData.filter(i=>i.name.toLowerCase().includes(val.toLowerCase()) ||  
                                                    i.whatsapp.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.phone2.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.phone3.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.phone4.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.area.toLowerCase().includes(val.toLowerCase()));
         if(val.length <= 0){
             setFilteredCustomer([])
         }else{
             setFilteredCustomer(filteredData)
         }
        }

        const preventMinus = (e) => { 
            if((e.target.value).includes("-")){
                setPayingAmount(Math.abs(e.target.value))
            }
          };

    useEffect(()=>{
        generateOrderNo();
    })

    useEffect(()=>{
        getProductData();
    },[])

    useEffect(()=>{
        getCustomerData();
    },[])

    

    return (
        <>
        <ToastContainer/>
            <div className='mt-4 py-4'>
            <Navbar/>
                    <div className='row'>
                        <div className='col-12 col-md-4 m-0'>
                        <div className='card product-list'>
                        <div className='table-responsive'>
                        <table className="table table-sm">
                        <thead>
                            <tr className='table-title'>
                            <th scope="col">Name</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Price</th>
                            <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cart.map((data,i)=>{
                                    return(
                                        <tr>
                                            <td className='table-title'>{data.name}</td>
                                            <td>{data.qty}</td>
                                            <td>₹ {data.net}</td>
                                            <td><button className='btn btn-sm brand-Btn' onClick={()=>deleteItem(i,data)}>Delete</button></td>
                                            </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                        </table>
                        </div>
                        </div>    
                        </div>
                        <div className='col-12 col-md-8 ps-0'>
                             <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='row'>
                                    <div className='col-12 px-1 mb-4'>
                                       <div className='row m-0'>
                                       <div className='col-12 col-md-6 px-1'>
                                            <div className='productList'>
                                            <input className="form-control form-control-sm mb-1" onChange={(e)=>searchProduct(e.target.value)} placeholder='Search product by name and barcode'/>
                                            <table className="table table-sm small">
                                                <tbody>
                                                    {
                                                        filteredProduct.map((data,i)=>{
                                                            return(
                                                                <tr>
                                                                    <td>{data.name}</td>
                                                                    <td>
                                                                        <div className='row m-0' style={{width : 70}}>
                                                                            <div className='col-12 px-0'>
                                                                            <input type="number" value={currentQuantity} onChange={(e)=> setCurrentQuantity(e.target.value)} className="form-control form-control-sm" />
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </td>
                                                                    <td>₹ {data.sellingPrice}</td>
                                                                    <td><button className='btn btn-sm brand-Btn' onClick={()=>pushToCart(data,currentQuantity)}>Add</button></td>
                                                                    </tr>
                                                            )
                                                        })
                                                    }
                                                    
                                                </tbody>
                                                </table>
                                            </div>
                                            <div className='mt-4'>
                                                <button type="button" className="btn btn-sm showBillBtn mx-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                View Bill
                                                </button>
                                                <ReactToPrint
                                                    trigger={() => <button type="button" className="btn btn-sm showBillBtn mx-1">Print Bill Only</button>}
                                                    content={() => componentRef.current}
                                                />
                                                <button type="button" className="btn btn-sm showBillBtn mx-1" onClick={()=>window.location.reload(false)}>
                                                Refresh
                                                </button>
                                                <button type="button" disabled={selectedCustomer.name ? false : true} className="btn btn-sm brand-Btn mx-1" data-bs-toggle="modal" data-bs-target="#confirmbilling">
                                                Save and Print Bill
                                                </button>
                                                <hr className='my-4' />
                                                    <div className='row justify-content-between'>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-text'>SubTotal</h5>
                                                        </div>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-text'>₹ {subtotal}</h5>
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-between'>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-text'>Discount</h5>
                                                        </div>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-text'>₹ {discount}</h5>
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-between'>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-text'>Earned Loyalty Points</h5>
                                                        </div>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-text'>{loyaltyPoint} pts</h5>
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-between'>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-text'>Extra Charge</h5>
                                                        </div>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-text'>₹ {charge}</h5>
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-between'>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-total'>Total</h5>
                                                        </div>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='price-total'>₹ {grandTotal}</h5> 
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 px-1'>
                                        <div className='customerList'>
                                            <input className="form-control form-control-sm mb-1" onChange={(e)=>searchCustomer(e.target.value)} placeholder='Search Customer by Name or Number'/>
                                            <table className="table table-sm small">
                                                <tbody>
                                                    {
                                                        filteredCustomer.map((data,i)=>{
                                                            return(
                                                                <tr>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.whatsapp}</td>
                                                                    <td>{data.area}</td>
                                                                    <td><button className='btn btn-sm brand-Btn' onClick={()=>setSelectedCustomer(data)}>Select</button></td>
                                                                    </tr>
                                                            )
                                                        })
                                                    }
                                                    
                                                </tbody>
                                                </table>
                                            </div>
                                            <div className='row'>
                                            <div className='col-12 px-1'>
                                                 <div className='username mt-2 mb-3'>
                                                    {
                                                        selectedCustomer.name
                                                        ?
                                                        <>
                                                            <p className='small m-0 mb-1' style={{fontWeight : 600}}>{selectedCustomer.name} - {selectedCustomer.whatsapp}</p>
                                                            <p className='small m-0 mb-0' style={{fontWeight : 600}}>Total Loyalty Points - {selectedCustomer.loyaltyPoint}</p> 
                                                        </>
                                                        :
                                                        <><p className='small m-0 mb-0' style={{fontWeight : 600}}>Please select the Customer</p> </>
                                                    }
                                                </div>
                                                </div>
                                                <div className='col-12 col-md-6 px-1'>
                                                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Discount Amount</p>
                                                    <input className="form-control form-control-sm mb-2" type="number" placeholder='0' onChange={calculateDiscount}/>
                                                </div>
                                                <div className='col-12 col-md-6 px-1'>
                                                <p className='small m-0 mb-2' style={{fontWeight : 600}}>Extra Charge's</p>
                                                    <input className="form-control form-control-sm mb-2" type="number" placeholder='0' onChange={calculateCharge}/>
                                                </div>
                                                <div className='col-12 px-0'>
                                                <div className='row justify-content-between align-items-center username'>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='totalBillText fw-bold m-0'>Paying Amount</h5>
                                                        </div>
                                                        <div className='col-6 px-0'>
                                                        <div class="input-group input-group-lg">
                                                            <span class="input-group-text" id="inputGroup-sizing-lg">₹</span>
                                                            <input 
                                                            className="form-control" 
                                                            type="number"
                                                            min={0}
                                                            value={payingAmount}
                                                            onKeyUp={preventMinus}
                                                            onChange={(e)=>setPayingAmount(e.target.value === "" || e.target.value === "-" ? 0 : e.target.value)} 
                                                            onPaste={(e)=>{
                                                            e.preventDefault()
                                                            return false;
                                                            }}/>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 px-0'>
                                                <div className='row justify-content-between align-items-center last-box '>
                                                        <div className='col-auto px-1'>
                                                            <h5 className='totalBillText fw-bold m-0'>Balance</h5>
                                                        </div>
                                                        <div className='col-6 px-0 text-end'>
                                                            <h5 className='totalBillText m-0'>₹ {grandTotal - payingAmount}</h5>
                                                        </div>
                                                </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       </div>
                                     </div>
                                    </div>
                                </div>
                             </div>   
                        </div>
                </div>
        </div>

        <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
            <div className="modal-header">
            <p className="modal-title" id="exampleModalLabel">Total Bill</p>
            <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body modal-bg">
              <div className='inner-bg m-0 p-0' ref={componentRef}>
                    <div className="inner-border pb-3">
                    <div className='row m-0'>
                            <div className='col-12 text-center mb-3 pt-0'>
                                <img src={process.env.PUBLIC_URL + '/logo.png'} className='img-fluid bill-img mt-0' width={170} alt='' />
                                <p className='m-0 small billText'>Sri Amman Complex, GH Stop,</p>
                                <p className='m-0 small billText'> Athani Road, Sathyamangalam - 638401</p>
                                <p className='m-0 small billText'>www.nelvanam.com</p>
                                <p className='m-0 small billText'>Ph - +91 8903072643</p>
                            </div>
                            <div className='col-12 px-1 text-start'>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-auto px-0'>
                                        <p className='m-0 small billText'>Customer: {selectedCustomer.name}</p>
                                        <p className='m-0 small billText'>Order No: {orderNo}</p>
                                    </div>
                                    <div className='col-auto px-0'>
                                        <p className='m-0 small billText'>Date: {date}</p>
                                        <p className='m-0 small billText'>Time: {time}</p>
                                    </div>
                                </div>
                                 <div className="mt-2 mb-1 hr-bill"></div>
                                 <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>ITEMS</p>
                                    </div>
                                    <div className='col-2 px-0 text-end'>
                                        <p className='m-0 small billText'>RATE</p>
                                    </div>
                                    <div className='col-2 px-0 text-end'>
                                        <p className='m-0 small billText'>QTY</p>
                                    </div>
                                    <div className='col-2 px-0 text-end'>
                                        <p className='m-0 small billText'>NET</p>
                                    </div>
                                </div>
                                <div className="mt-1 mb-2 hr-bill"></div>

                                {
                                    cart.map((data,i)=>{
                                        return(
                                            <div className='row justify-content-between m-0'>
                                                <div className='col-6 px-0'>
                                                    <p className='m-0 billTextName'>{data.name}</p>
                                                </div>
                                                <div className='col-2 px-0 text-end'>
                                                    <p className='m-0 small billText'>₹ {data.rate}</p>
                                                </div>
                                                <div className='col-2 px-0 text-end'>
                                                    <p className='m-0 small billText'>{data.qty}</p>
                                                </div>
                                                <div className='col-2 px-0 text-end'>
                                                    <p className='m-0 small billText'>₹ {data.net}</p>
                                                </div>
                                            </div>
                                        )
                                    })    
                                }

                                <div className="mt-3 mb-1 hr-bill"></div>
                                 <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>SUBTOTAL</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {subtotal}</p>
                                    </div>
                                </div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>DISCOUNT</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {discount}</p>
                                    </div>
                                </div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>EXTRA CHARGE</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {charge}</p>
                                    </div>
                                </div>
                                {
                                    showPointCancel
                                    ?
                                    <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>USED LOYALTY POINT</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>- ₹ {selectedCustomer.loyaltyPoint}</p>
                                    </div>
                                </div>
                                :
                                <></>
                                }
                                <div className="mt-1 mb-2 hr-bill"></div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billTextTotal'>TOTAL</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billTextTotal'>₹ {grandTotal}</p>
                                    </div>
                                </div>
                                <div className="mt-2 mb-3 hr-bill"></div>
                                <div className='row justify-content-between m-0 mb-2'>
                                    <div className='col-6 px-0'>
                                    <p className='m-0 loyaltyPoints'>TOTAL ITEMS : {cart.length}</p>
                                    </div>
                                </div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>PAID AMOUNT</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {payingAmount-0}</p>
                                    </div>
                                </div>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-6 px-0'>
                                        <p className='m-0 small billText'>Balance</p>
                                    </div>
                                    <div className='col-3 px-0 text-end'>
                                        <p className='m-0 small billText'>₹ {grandTotal - payingAmount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 px-1 text-start my-2'>
                            {/* <p className='m-0 px-2 small paymentType mb-2'>CASH</p> */}
                                <p className='m-0 small loyaltyPoints'>Loyalty Points : {!allowLoyaltyPoint ? loyaltyPoint : 0} Points</p>
                            </div>
                            <div className='col-12 px-1 text-start mb-3'>
                                <p className='m-0 small term'>TERMS &amp; CONDITIONS</p>
                                <p className='m-0 mt-1 small term'>1. Goods sold cannot be returned or exchanged after long time.</p>
                                <p className='m-0 small term'>2. Any Complaint on product, the return is eligible</p>
                            </div>
                            <div className='col-12 text-center my-2'>
                                <p className='m-0 small billText'>***** Thank You Visit Again *****</p>
                            </div>
                    </div>
                    </div>
              </div>
            </div>
        </div>
        </div>
        </div>
        <div className="modal fade" id="confirmbilling" data-bs-backdrop="static" aria-labelledby="confirmLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mx-auto" style={{maxWidth : "400px"}}>
            <div className="modal-content">
                <div className="modal-header">
                <p className="modal-title mx-auto" id="confirmLabel">Confirm Billing</p>
                </div>
                <div className="modal-body pb-0">
                    <div className='row'> 
                        <div className='col-12 px-0 mb-3'>
                                <div className='row justify-content-between'>
                                <div className='col-auto px-0'>
                                    <h5 className='price-text'>Earned Loyalty Points</h5>
                                </div>
                                <div className='col-auto px-0'>
                                    <h5 className='price-text'>{!allowLoyaltyPoint ? loyaltyPoint : 0} pts</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 px-1 last-box-warn mb-3'>
                                {/* <p className='text-danger small fw-bold'>Please use this below option after added all other informations</p> */}
                               
                                <div className="form-check form-switch ms-2">
                                 <input className="form-check-input" type="checkbox" role="switch" onChange={()=>setAllowLoyaltyPoint(!allowLoyaltyPoint)} />
                                 <label className="form-check-label small" htmlFor="flexSwitchCheckChecked">Emi Billing (Don't give loyalty point)</label>
                                 </div>
                            </div>
                            <div className='col-12 px-1 last-box mb-3'>
                                <div className="form-check form-switch ms-2">
                                 <input className="form-check-input" type="checkbox" role="switch" onChange={useLoyaltyPoint} />
                                 <label className="form-check-label small" htmlFor="flexSwitchCheckChecked">Use Loyalty Points to buy products</label>
                                 </div>
                            </div>
                            <div className='col-12 px-0 mb-0'>
                                    <div className='row justify-content-between'>
                                        <div className='col-6 px-0'>
                                            <p className='small m-0'>Sub Total</p>
                                        </div>
                                        <div className='col-3 px-0 text-end'>
                                            <p className='small m-0'>₹ {subtotal}</p>
                                        </div>
                                   </div>
                            </div>
                            <div className='col-12 px-0 m-0'>
                                    <div className='row justify-content-between mt-0'>
                                        <div className='col-6 px-0'>
                                            <p className='small m-0'>Used Loyalty Points</p>
                                        </div>
                                        <div className='col-3 px-0 text-end'>
                                            <p className='small m-0'>₹ {showPointCancel ? selectedCustomer.loyaltyPoint : 0}</p>
                                        </div>
                                   </div>
                            </div>
                            <div className='col-12 px-0 mb-3 mt-0'>
                                <div className='row justify-content-between'>
                                     <div className='col-auto px-0'>
                                         <h5 className='price-text'>Total</h5>
                                     </div>
                                     <div className='col-auto px-0'>
                                         <h6 className='price-text'>₹ {grandTotal}</h6> 
                                     </div>
                                 </div>
                            </div>
                    </div>
                    <div className='row'>
                                <div className='col-6'>
                                    <div className="d-grid gap-2">
                                        <button type="button" className="btn btn-sm btnClose" data-bs-dismiss="modal">Cancel</button>&nbsp;
                                    </div>
                                </div>
                                <div className='col-6'>
                                   <div className="d-grid gap-2">
                                        <button type="button" className="btn btn-sm brand-Btn" onClick={submitSale} data-bs-dismiss="modal">Save &amp; Print</button>
                                    </div>
                                </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}
