import React, {useState , useEffect} from 'react';
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';
import "../Page.css";
import {
    Link,
  } from "react-router-dom";

export default function Addtrader() {

      const [companyName , setCompanyName] = useState("");
      const [ownerName , setOwnerName] = useState("");
      const [phone2 , setPhone2] = useState("");
      const [phone1 , setPhone1] = useState("");
      const [address , setAddress] = useState("");
      const [maplink , setMaplink] = useState("");
      const [email , setEmail] = useState("");
      const [pincode , setPincode] = useState("");
      const [area , setArea] = useState("");
      const [city , setCity] = useState("");

       
         const refreshForm = () => {
            setCompanyName("");
            setOwnerName("");
            setPhone2("");
            setPhone1("");
            setAddress("");
            setMaplink("");
            setEmail("");
            setPincode("");
            setArea("");
            setCity("");
         }

    const [areaData , setAreaData] = useState([]);
    const [cityData , setCityData] = useState([]);



    const getAreaData = async() => {
      try{
         const res = await axios.get(`${baseUrl}/get/getallarea`);
         setAreaData(res.data);
      }catch(e){
         toast.error("Connection Error");
      }
   }

   const getCityData = async() => {
      try{
         const res = await axios.get(`${baseUrl}/get/getallcity`);
         setCityData(res.data);
      }catch(e){
         toast.error("Connection Error");
      }
   }


      const submitSupplier = async(e) => {
            try{
              e.preventDefault();
              const tempData = {
                  companyName : companyName,
                  ownerName : ownerName,
                  phone1 : phone1,
                  phone2 : phone2,
                  address : address,
                  maplink : maplink,
                  email : email,
                  pincode : pincode,
                  area : area,
                  city : city
              };

                  await toast.promise(
                  axios.post(`${baseUrl}/post/newsupplier`,tempData),
                 {
                 pending: 'Loading in Progress',
                 success: 'Supplier Added',
                 error: 'Process Rejected'
                 }
             )
                refreshForm();

            }catch(e){
              toast.error("Connection Error")
            }
          }


          useEffect(()=>{
            getAreaData();
         },[])
    
         useEffect(()=>{
            getCityData();
         },[])


    return (
        <div className='contain'>
              <ToastContainer/>
        <form onSubmit={submitSupplier}>
        <nav className='add-navbar fixed-top'>
        <div className='row justify-content-between align-items-center m-0 py-3'>
        <div className='col-auto'>
        <Link to="/trader" className="btn btn-sm brand-Btn">Back</Link>
            </div>
            |
            <div className='col'>
                <p className='small m-0' style={{fontWeight : 600}}>Add a Supplier</p>
            </div>
            <div className='col-auto'>
            <button type="submit" className="btn btn-sm brand-Btn">Save Supplier</button>
            </div>
        </div>
        </nav>
       <div className='container my-5 py-5'>
       <div className="row justify-content-center">
          <div className="col-12 col-md-7 px-1">
            <h5 className='my-4' style={{fontWeight : 600}}>Supplier Information</h5>
            <p className='text-secondary small' style={{fontWeight : 600}}>Supplier Information</p>
            <div className='row'>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Company Name</p>
                  <input className="form-control form-control-sm mb-3" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}
                   placeholder="Enter company name"/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Owner or Manager Name *</p>
                  <input className="form-control form-control-sm mb-3" value={ownerName} onChange={(e)=>{setOwnerName(e.target.value)}}
                  placeholder="Enter person name" required/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Phone Number 1 *</p>
                  <input className="form-control form-control-sm mb-3"  value={phone1} onChange={(e)=>{setPhone1(e.target.value)}}
                  placeholder="Enter phone number"/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Phone Number 2 </p>
                  <input className="form-control form-control-sm mb-3" value={phone2} onChange={(e)=>{setPhone2(e.target.value)}}
                  placeholder="Enter phone number"/>
            </div>
            <div className='col-12 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Address</p>
            <textarea className="form-control form-control-sm mb-3" rows={3}  value={address} onChange={(e)=>{setAddress(e.target.value)}}
            placeholder="Enter Supplier's address"></textarea>
            </div>
            <div className='col-12 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Map Link from (What 3 Words App)</p>
                  <input className="form-control form-control-sm mb-3"  value={maplink} onChange={(e)=>{setMaplink(e.target.value)}}
                  placeholder="Map Link"/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Email Address</p>
                  <input className="form-control form-control-sm mb-3"  value={email} onChange={(e)=>{setEmail(e.target.value)}}
                  placeholder="Enter email address"/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Pincode</p>
                  <input className="form-control form-control-sm mb-3"  value={pincode} onChange={(e)=>{setPincode(e.target.value)}}
                  placeholder="Pincode"/>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Area Name</p>
            <select className="form-select form-select-sm text-secondary mb-3" value={area} onChange={(e)=>{setArea(e.target.value)}}>
                    <option selected value="None">Select Area Name</option>
                    {
                          areaData.map((data,i)=>{
                                return(
                                    <option value={data.name} key={i}>{data.name}</option>
                                )
                          })
                    }
                    </select>
            </div>
            <div className='col-12 col-md-6 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>City Name</p>
            <select className="form-select form-select-sm text-secondary mb-3" value={city} onChange={(e)=>{setCity(e.target.value)}}>
                    <option selected value="None">Select City Name</option>
                    {
                          cityData.map((data,i)=>{
                                return(
                                    <option value={data.name} key={i}>{data.name}</option>
                                )
                          })
                    }
                </select>
            </div>
            </div>
           </div>
        </div>
       </div>
        </form>
    </div>
    )
}
