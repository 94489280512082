import React , {useEffect ,useState , useRef} from 'react'
import Navbar from './Navbar'
import { useParams , Link } from "react-router-dom";
import axios from "axios";
import ReactToPrint from 'react-to-print';
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';
import api from "../utils/Secret";
import S3 from 'react-aws-s3';


export default function Showsupplier() {

    
    let { slug } = useParams();
    const componentRef = useRef();

    const [userData , setUserData] = useState({});
    const [loader , setLoader] = useState(true);
    const [s3,setS3] = useState({});
    
    const [selectedBill , setSelectedBill] = useState({});
    
    const getSupplierData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getsinglesupplier/${slug}`);
           setUserData(res.data);
           setLoader(false);
        }catch(e){
           toast.error("Connection Error");
        }
     }

     const config = {
        bucketName: api.bucketName,
        region: api.region,
        accessKeyId: api.accessKeyId, 
        secretAccessKey: api.secretAccessKey 
    }

    const ReactS3Client = new S3(config);


    const setFile = async (e) => {
        const file = e.target.files[0];
            await toast.promise(
                ReactS3Client.uploadFile(file).then(data => setS3(data)),
               {
               pending: 'Uploading Bill, Please Wait',
               success: 'Successfully Uploaded',
               error: 'Upload Rejected'
               }
           )
           
    }


    const submitBill = async(data) => {
        try{
            const tempData = {
                url :data.location
            }

            await toast.promise(
                axios.post(`${baseUrl}/post/newbill/${userData._id}`,tempData),
               {
               pending: 'Loading in Progress',
               success: 'Billing Created',
               error: 'Process Rejected'
               }
           )
           getSupplierData();
        }catch(e){
            toast.error("Connection Error")
        }
    }

    const deleteBill = async(data) => {
        try{

            await toast.promise(
                axios.delete(`${baseUrl}/delete/deletebill/${data._id}`),
               {
               pending: 'Loading in Progress',
               success: 'Bill Deleted',
               error: 'Process Rejected'
               }
           )

               getSupplierData();
        }catch(e){
            toast.error("Connection Error")
        }
    }
    

     useEffect(()=>{
        getSupplierData();
     },[]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
        <ToastContainer/>
        <Navbar/>
            <div className='mt-4 py-4'>
        {
            loader ?
            <div className="text-center">
                  <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                   </div>
                   <p><small>Loading...</small></p>
           </div>
            :
            <div className="row mt-4">
          <div className="col-12 col-md-3">
                <div className='card stat-card'>
                    <div className='card-body'>
                    <h4 className='fw-bold text-secondary'>{userData.ownerName}</h4>
                 <p className='px-2 gold'>{userData.companyName}</p>
                        <Link to={`/editsupplier/${userData._id}`}>
                            <button className="showBillBtn">
                            Edit Supplier
                            </button>
                        </Link> &nbsp;
                        <button className="showBillBtn" data-bs-toggle="modal" data-bs-target="#updatestock">
                            Add New Bill
                         </button>
                     <h6 className='fw-bold px-0 mt-4'>Supplier Details</h6>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Phone Number 1:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.phone1}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Phone Number 2:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.phone2}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Address:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.address}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Map Link:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.maplink}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Email:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.email}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Pincode:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.pincode}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>Area Name:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.area}</p>
                                </div>
                            </div>
                            <div className='row mt-0 mx-0 justify-content-between'>
                                <div className='col px-0'>
                                    <p className='m-0 table-title'>City:</p>
                                </div>
                                <div className='col px-0'>
                                     <p className='m-0 table-data'>{userData.city}</p>
                                </div>
                            </div>
                    </div>
                </div>    
            </div>
                        <div className="col-12 col-md-5">
                            <div className='card'>
                                <div className='card-body p-0 saleList'>
                                <div className='row justify-content-between align-items-center m-0 py-2 borderbottom'>
                                    <div className='col-4'>
                                        <p className='table-title m-0'>Supplied Date</p>
                                    </div>
                                    <div className='col-2 text-center'>
                                        <p className='table-title m-0'>Delete</p>
                                    </div>
                                    <div className='col-2 text-center'>
                                        <p className='table-title m-0'>Bill</p>
                                    </div>
                            </div>
                       {
                           userData.bills.length <= 0 ?
                            <p className='text-center small text-secondary mt-5'>No Sales Record</p>
                           :
                           userData.bills.map((data,i) => {
                                return(
                                    <div className='row justify-content-between align-items-center m-0 my-1 p-0' key={i}>
                            <div className='col-4'>
                                <p className='table-data m-0'>{new Date(data.createdAt).toDateString()}</p>
                            </div>
                            <div className='col-2 text-center'>
                                <button className='showBillBtn' onClick={()=>deleteBill(data)}>
                                    Delete
                                </button>
                            </div>
                            <div className='col-2 text-center'>
                                <button className='showBillBtn' onClick={()=>setSelectedBill(data)}>
                                    Show Bill
                                </button>
                            </div>
                            </div>
                                )
                           })
                       }
                </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            {
                !selectedBill.url 
                ?
                <p className='table-title text-center'>No Bill Selected</p>
                :
                <>
                     <div className='d-grid gap-2 mb-3'>
                     <ReactToPrint
                                trigger={() => <button className='btn brand-Btn btn-sm'>
                                                    Print Below Bill
                                                </button>}
                                content={() => componentRef.current}
                           />       
          </div>
              <div className='card'>
                    <div className='card-body p-0 billList'>
                    <div className='inner-bg m-0 p-0' ref={componentRef}>
                    <div className="inner-border pb-3">
                    <div className='row'>
                    <div className='col-12 text-center mb-3 pt-0'>
                                <img src={process.env.PUBLIC_URL + '/logo.png'} className='img-fluid bill-img mt-0' width={170} alt='' />
                                <p className='m-0 small billText'>Sri Amman Complex, GH Stop,</p>
                                <p className='m-0 small billText'> Athani Road, Sathyamangalam - 638401</p>
                                <p className='m-0 small billText'>www.nelvanam.com</p>
                                <p className='m-0 small billText'>Ph - +91 8903072643</p>
                            </div>
                            <div className='col-12 text-start'>
                                <div className='row justify-content-between m-0'>
                                    <div className='col-auto px-0'>
                                        <p className='m-0 small billText'>Company Name: {userData.companyName}</p>
                                        <p className='m-0 small billText'>Supplier Name: {userData.ownerName}</p>
                                        <p className='m-0 small billText'>Uploaded Date: {new Date(selectedBill.createdAt).toDateString()}</p>
                                    </div>
                                    <div className='col-auto px-0'>
                                        <p className='m-0 fw-bold'>Supplier Bill</p>
                                    </div>
                                </div>
                                <div className="mt-2 mb-1 hr-bill"></div>
                                    <img className='img-fluid w-100' src={selectedBill.url} alt="Bill img" />
                                  
                                <div className="mt-1 mb-2 hr-bill"></div>
                            </div>
                            <div className='col-12 text-center my-2'>
                                <p className='m-0 small billText'>***** Thank You Visit Again *****</p>
                            </div>
                    </div>
                    </div>
              </div>
                    </div>
              </div>
          
                </>
            }
          </div>
        </div>
        }
        
    </div>
    <div className="modal fade" id="updatestock" data-bs-backdrop="static" aria-labelledby="updateLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                <p className="modal-title" id="updateLabel">Upload Supplier Bills</p>
                </div>
                <div className="modal-body">
                <div className='row'>
                <div className='col-12 px-1'>
                    <p className='small m-0 mb-2' style={{fontWeight : 600}}>Upload the Supplier Bill Below</p>
                    </div>
                <div className='col-12 px-1'>
                        <input className="form-control form-control-sm mb-3" onChange={setFile}
                        type="file"/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-sm btnClose" data-bs-dismiss="modal">Cancel</button>
                <button type="button" onClick={()=>submitBill(s3)} className="btn btn-sm brand-Btn" disabled={s3.location ? false : true} data-bs-dismiss="modal">SAVE</button>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}
