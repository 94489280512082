import React , {useEffect , useState, useRef} from 'react'
import Navbar from './Navbar';
import ReactToPrint from 'react-to-print';
import axios from "axios";
import baseUrl from '../utils/Url';

import { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';

export default function Emibilling() {

    const componentRef = useRef();

    const [customer , setCustomer] = useState({});
    const [filteredCustomer , setFilteredCustomer] = useState([]);

    const [customerData , setCustomerData] = useState([]);

    const [payingAmount , setPayingAmount] = useState(0);


    
    const tempTime = new Date()
    const date = tempTime.getDate() + "-" + (tempTime.getMonth() + 1) + "-" + tempTime.getFullYear();
    const time =  ((tempTime.getHours() === 0) ?
                    12 : 
                    (tempTime.getHours()) > 12 ? 
                    (tempTime.getHours()-12) : 
                    (tempTime.getHours()))
                    + " : " + 
                    tempTime.getMinutes()
                    + " " + 
                    ((tempTime.getHours() < 12 && tempTime.getHours() >= 0) ? "AM" : "PM")
   
    const [emiNo , setEmiNo] = useState("");

    const generateEmiNo = () => {
        const tempEmiNo = tempTime.getDate() +"" + (tempTime.getMonth() + 1)  +"" + tempTime.getMinutes() +"" + tempTime.getHours() +"" + tempTime.getSeconds();
        setEmiNo(tempEmiNo);
    }


     const getCustomerData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallcustomer`);
           setCustomerData(res.data);
        }catch(e){
           toast.error("Connection Error");
        }
     }


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

    const submitEmi = async() => {
        try{
            const tempData = {
                customerName : customer.name,
                customerId : customer._id,
                emiNo : emiNo,
                pendingAmount : customer.balance,
                payingAmount : payingAmount,
                balance : (customer.balance - payingAmount),
                date : date,
                time : time
            }

            await toast.promise(
                axios.post(`${baseUrl}/post/newemi`,tempData),
               {
               pending: 'Loading in Progress',
               success: 'Emi Bill Added',
               error: 'Process Rejected'
               }
           )
                handlePrint();
        }catch(e){
            toast.error("Connection Error");
        }
    }

   

    const searchCustomer = (val) => {
       const filteredData = customerData.filter(i=>i.name.toLowerCase().includes(val.toLowerCase()) ||  
                                                    i.whatsapp.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.phone2.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.phone3.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.phone4.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.area.toLowerCase().includes(val.toLowerCase()));
        if(val.length <= 0){
            setFilteredCustomer([])
        }else{
            setFilteredCustomer(filteredData)
        }
       }

    
    useEffect(()=>{
        generateEmiNo();
    })


    useEffect(()=>{
        getCustomerData();
    },[])

    const preventMinus = (e) => {
        if((e.target.value).includes("-")){
            setPayingAmount(Math.abs(e.target.value))
        }
      };
    

    return (
        <>
        <ToastContainer/>
            <div className='mt-4 py-4'>
            <Navbar/>
                    <div className='row'>
                        <div className='col-12 col-md-4 m-0'>
                        <div className='card product-list'>
                        <div className='inner-bg m-0 p-0' ref={componentRef}>
                            <div className="inner-border pb-3">
                            <div className='row m-0'>
                                    <div className='col-12 text-center mb-3 pt-0'>
                                        <img src={process.env.PUBLIC_URL + '/logo.png'} className='img-fluid bill-img mt-0' width={170} alt='' />
                                        <p className='m-0 small billText'>Sri Amman Complex, GH Stop,</p>
                                        <p className='m-0 small billText'> Athani Road, Sathyamangalam - 638401</p>
                                        <p className='m-0 small billText'>www.nelvanam.com</p>
                                        <p className='m-0 small billText'>Ph - +91 8903072643</p>
                                    </div>
                                    <div className='col-12 px-1 text-start'>
                                        <div className='row justify-content-between m-0'>
                                            <div className='col-auto px-0'>
                                                <p className='m-0 small billText'>Customer: {customer.name}</p>
                                                <p className='m-0 small billText'>EMI No: {emiNo}</p>
                                            </div>
                                            <div className='col-auto px-0'>
                                                <p className='m-0 small billText'>Date: {date}</p>
                                                <p className='m-0 small billText'>Time: {time}</p>
                                            </div>
                                        </div>
                                        <div className="mt-2 mb-1 hr-bill"></div>
                                        <div className='row justify-content-between m-0'>
                                            <div className='col-6 px-0'>
                                                <p className='m-0 small billText'>PENDING AMOUNT</p>
                                            </div>
                                            <div className='col-3 px-0 text-end'>
                                                <p className='m-0 small billText'>₹ {customer.balance}</p>
                                            </div>
                                        </div>
                                        <div className="mt-1 mb-2 hr-bill"></div>
                                        <div className='row justify-content-between m-0'>
                                            <div className='col-6 px-0'>
                                                <p className='m-0 small billTextTotal'>PAID AMOUNT</p>
                                            </div>
                                            <div className='col-3 px-0 text-end'>
                                                <p className='m-0 small billTextTotal'>₹ {payingAmount}</p>
                                            </div>
                                        </div>
                                        <div className="mt-2 mb-3 hr-bill"></div>
                                        
                                        <div className='row justify-content-between m-0'>
                                            <div className='col-6 px-0'>
                                                <p className='m-0 small billText'>BALANCE</p>
                                            </div>
                                            <div className='col-3 px-0 text-end'>
                                                <p className='m-0 small billText'>₹ {customer.balance - payingAmount}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 text-center my-3'>
                                        <p className='m-0 small billText'>***** Thank You Visit Again *****</p>
                                    </div>
                            </div>
                            </div>
                    </div>
                        </div>    
                        </div>
                        <div className='col-12 col-md-8 ps-0'>
                             <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='row'>
                                    <div className='col-12 px-1 mb-4'>
                                       <div className='row m-0'>
                                       <div className='col-12 col-md-12 px-1'>
                                            <div className='productList'>
                                            <input className="form-control form-control-sm mb-1" onChange={(e)=>searchCustomer(e.target.value)} placeholder='Search customer by name or phone number'/>
                                            <table className="table table-borderless table-sm small">
                                                <thead className='borderbottom'>
                                                    <tr>
                                                        <th>Customer Name</th>
                                                        <th>Balance</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredCustomer.map((data,i)=>{
                                                            return(
                                                                <tr key={i}>
                                                                    <td>{data.name}</td>
                                                                    <td>₹ {data.balance}</td>
                                                                    <td><button className='btn btn-sm brand-Btn' disabled={data.balance === 0 ? true : false} onClick={()=>setCustomer(data)}>Select</button></td>
                                                                    </tr>
                                                            )
                                                        })
                                                    }
                                                    
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                       </div>
                                     </div>
                                     <div className='col-12 px-1 mb-4'>
                                       <div className='row m-0'>
                                       <div className='col-12 col-md-6 px-1'>
                                        <button type="button" className="btn btn-sm showBillBtn mx-1" onClick={()=>window.location.reload(false)}>
                                                Refresh
                                        </button>
                                        <ReactToPrint
                                            trigger={() => <button type="button" className="btn btn-sm showBillBtn mx-1">Print Bill Only</button>}
                                            content={() => componentRef.current}
                                        />
                                        <button type="button" className="btn btn-sm brand-Btn mx-1" data-bs-toggle="modal" data-bs-target="#confirmbilling">
                                           Save and Print Bill
                                        </button>
                                        </div>
                                        <div className='col-12 username col-md-6 px-1'>
                                            <div className='row justify-content-between'>
                                                <div className='col-auto px-1'>
                                                    <h5 className='price-text'>Pending Amount</h5>
                                                </div>
                                                <div className='col-auto px-1'>
                                                    <h5 className='price-text'>₹ {customer.balance}</h5>
                                                </div>
                                            </div>
                                            <div className='row justify-content-between align-items-center'>
                                                <div className='col-auto px-1'>
                                                    <h5 className='price-text'>Now Paying</h5>
                                                </div>
                                                <div className='col-5 px-1'>
                                                
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">&nbsp;₹&nbsp;</span>
                                                    <input 
                                                    className="form-control" 
                                                    type="number" 
                                                    placeholder='0' 
                                                    onKeyUp={preventMinus}
                                                    value={payingAmount}
                                                    onChange={(e)=>setPayingAmount(e.target.value === "" || e.target.value === "-" ? 0 : e.target.value)}
                                                    onPaste={(e)=>{
                                                        e.preventDefault()
                                                        return false;
                                                        }} />
                                                </div>
                                                </div>
                                            </div>
                                            <div className='row justify-content-between'>
                                                <div className='col-auto px-1'>
                                                    <h5 className='price-total'>Balance After Now</h5>
                                                </div>
                                                <div className='col-auto px-1'>
                                                    <h5 className='price-total'>₹ {customer.balance - payingAmount}</h5> 
                                                </div>
                                            </div>
                                        </div>
                                       </div>
                                     </div>
                                    
                                        
                                    </div>
                                </div>
                             </div>   
                        </div>
                </div>
        </div>
        <div className="modal fade" id="confirmbilling" data-bs-backdrop="static" aria-labelledby="confirmLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                <p className="modal-title mx-auto" id="confirmLabel">Confirm Billing</p>
                </div>
                <div className="modal-body pb-0">
                    <div className='row'>
                                <div className='col-6'>
                                    <div className="d-grid gap-2">
                                        <button type="button" className="btn btn-sm btnClose" data-bs-dismiss="modal">Cancel</button>&nbsp;
                                    </div>
                                </div>
                                <div className='col-6'>
                                   <div className="d-grid gap-2">
                                        <button type="button" className="btn btn-sm brand-Btn"  onClick={submitEmi} data-bs-dismiss="modal">Save &amp; Print</button>
                                    </div>
                                </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}
