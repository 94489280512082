import React, {useState , useEffect} from 'react';
import Navbar from './Navbar'
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';

import {
    Link,
  } from "react-router-dom";

export default function Supplier() {


    
    const [supplierData , setSupplierData] = useState([]);
    const [filterSupplier , setFilterSupplier] = useState([]);
    const [confirmpassword , setConfirmPassword] = useState("");
    const [currentUser , setCurrentUser] = useState({});

    const [loader,setLoader] = useState(true);

    const getSupplierData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallsupplier`);
           setSupplierData(res.data);
           setFilterSupplier(res.data);
           setLoader(false);
        }catch(e){
           toast.error("Connection Error");
        }
     }


     const deleteSupplier = async(userData) => {
        try{

          await toast.promise(
            axios.delete(`${baseUrl}/delete/deletesupplier/${userData}`),
           {
           pending: 'Loading in Progress',
           success: 'Supplier Deleted',
           error: 'Process Rejected'
           }
       )
                setConfirmPassword("");
                getSupplierData();
         }catch(e){
            toast.error("Connection Error");
         }
     }


     const searchSupplier = (val) => {
        const filteredData = supplierData.filter(i=>i.companyName.toLowerCase().includes(val.toLowerCase()) ||
                                                    i.phone1.toLowerCase().includes(val.toLowerCase()) || 
                                                    i.ownerName.toLowerCase().includes(val.toLowerCase()) || 
                                                    i.area.toLowerCase().includes(val.toLowerCase()));
         setFilterSupplier(filteredData);
     }
    
    
      useEffect(()=>{
        getSupplierData();
      },[])


    return (
        <>
        <ToastContainer/>
        <Navbar/>
            <div className='mt-4 py-4'>
        <div className='row justify-content-between align-items-center mt-4 p-0'>
            <div className='col-auto'>
                <h4 style={{fontWeight : 600,margin : "auto"}}>All Supplier</h4>
            </div>
            <div className='col-auto'>
            <Link to="/addsupplier" className="btn btn-sm brand-Btn">Add New Supplier</Link>
            </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-2 px-1">
                 <div className='card stat-card'>
                    <div className='card-body px-2'>
                      <input className="form-control form-control-sm mb-2" onChange={(e)=>searchSupplier(e.target.value)} type="search" placeholder="Search Supplier" aria-label="Search"/>
                      <p className='small px-2'>Use Supplier Name or Phone Number to search.</p>
                    </div>
                 </div>
          </div>
          <div className="col-12 col-md-10 px-1">
            <div className='card stat-card'>
                <div className='card-body'>
                {
                    loader ? 
                        <div className="text-center">
                            <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                            </div>
                            <p><small>Loading...</small></p>
                        </div>
                        :
                          <div className="table-responsive">
                          <table className="table table-borderless table-sm">
                              <thead className='borderbottom'>
                                <tr>
                                  <td className="table-title">Company Name</td>
                                  <td className="table-title">Owner/Manager</td>
                                  <td className="table-title">Phone.1</td>
                                  <td className="table-title">Phone.2</td>
                                  <td className='table-title'>Area</td>
                                  <td className="table-title">Joined On</td>
                                  <td className="table-title">Delete</td>
                                  <td className="table-title">View</td>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                      filterSupplier.map((data,i)=>{
                                        return(
                                          <tr className='borderbottom p-0' key={i}>
                                            <td className='table-data'>{data.companyName}</td>
                                            <td className='table-data'>{data.ownerName}</td>
                                            <td className='table-data'>{data.phone1}</td>
                                            <td className="table-data">{data.phone2}</td>
                                            <td className='table-data'>{data.area}</td>
                                            <td className='table-data'>{new Date(data.createdAt).toDateString()}</td>
                                            <td className='table-data'>
                                                <button className='showBillBtn' onClick={()=>setCurrentUser(data)} data-bs-toggle="modal" data-bs-target="#deletesupplier">
                                                          Delete Supplier
                                              </button>
                                              </td>
                                            <td className='table-data'>
                                                <Link to={`/supplier/${data._id}`}>
                                                    <button className='showBillBtn'>
                                                            Show Supplier
                                                </button>
                                                </Link>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                              </tbody>
                            </table>
                          </div>
                }
                </div>
            </div>
          </div>
        </div>
    </div>
  <div className="modal fade" id="deletesupplier" data-bs-backdrop="static" aria-labelledby="updateLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
     
     <div className="modal-content">
        <div className="modal-header">
          <p className="modal-title" id="updateLabel">Delete User Details</p>
        </div>
        <div className="modal-body">
          <div className='row'>
          <div className='col-12 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Enter this name below: <b>{currentUser.ownerName}</b></p>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>{currentUser._id}</p>
            </div>
          <div className='col-12 px-1'>
                  <input className="form-control form-control-sm mb-3" 
                  value={confirmpassword} onChange={(e)=>setConfirmPassword(e.target.value)} 
                  placeholder="Enter Supplier Name"/>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-sm btnClose" onClick={()=>setCurrentUser({})} data-bs-dismiss="modal">Cancel</button>
          <button type="submit" onClick={()=> deleteSupplier(currentUser._id)} disabled={confirmpassword === currentUser.ownerName ? false : true} className="btn btn-sm brand-Btn" data-bs-dismiss="modal">Delete</button>
        </div>
      </div>
    </div>
  </div>
        </>
    )
}
