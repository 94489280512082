/** @format */

import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import baseUrl from "../utils/Url";

import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Report from "./DashboardReport";
import EmiReport from "./Emichart";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faCubes,
  faDatabase,
  faFunnelDollar,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

export default function Dashboard() {
  const [totalCustomer, setTotalCustomer] = useState([]);
  const [totalSupplier, setTotalSupplier] = useState([]);
  const [totalSale, setTotalSale] = useState([]);
  const [totalUser, setTotalUser] = useState([]);
  const [totalProduct, setTotalProduct] = useState([]);

  const [loader, setLoader] = useState(true);

  const [lowQuantityProduct, setLowQuantityProduct] = useState([]);

  const getAllData = async () => {
    try {
      const customerRes = await axios.get(`${baseUrl}/get/getallcustomer`);
      setTotalCustomer(customerRes.data);
      const supplierRes = await axios.get(`${baseUrl}/get/getallsupplier`);
      setTotalSupplier(supplierRes.data);
      const saleRes = await axios.get(`${baseUrl}/get/getallsale`);
      setTotalSale(saleRes.data);
      const userRes = await axios.get(`${baseUrl}/get/getalluser`);
      setTotalUser(userRes.data);
      const productRes = await axios.get(`${baseUrl}/get/getallproduct`);
      setTotalProduct(productRes.data);
      setLoader(false);
    } catch (e) {
      toast.error("Connection Error");
    }
  };

  const lowQuanityProduct = async () => {
    try {
      const productRes = await axios.get(`${baseUrl}/get/getallproduct`);

      const data = productRes.data;

      const arr = [];

      data.forEach((data) => {
        if (data.quantity <= data.intimationRate) {
          arr.push(data);
        }
      });

      setLowQuantityProduct(arr);
    } catch (e) {
      toast.error("Connection Error");
    }
  };

  useEffect(() => {
    lowQuanityProduct();
  }, []);

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="mt-4 py-4">
        <div className="row">
          <div className="col-12 col-md-3 px-1 my-1">
            <div className="card stat-card">
              <div className="card-body">
                <div className="row m-0">
                  <div className="col-6 mb-1 ps-0 pe-2">
                    <div className="d-grid gap-2">
                      <Link to="/billing" className="btn btn-sm brand-Btn">
                        <FontAwesomeIcon icon={faCartArrowDown} /> &nbsp; New
                        Sale
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 mb-1 pe-0 ps-2">
                    <div className="d-grid gap-2">
                      <Link to="/emibilling" className="btn btn-sm brand-Btn">
                        <FontAwesomeIcon icon={faFunnelDollar} /> &nbsp; Pay EMI
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 ps-0 pe-2">
                    <div className="d-grid gap-2">
                      <Link to="/product" className="btn btn-sm brand-Btn">
                        <FontAwesomeIcon icon={faCubes} /> &nbsp; Update Stock
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 pe-0 ps-2">
                    <div className="d-grid gap-2">
                      <Link to="/addcustomer" className="btn btn-sm brand-Btn">
                        <FontAwesomeIcon icon={faUserPlus} /> &nbsp; New
                        Customer
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 px-1 my-1">
            <div className="row m-0">
              <div className="col-6 col-md-2 px-1 mb-2 mb-md-0">
                <div className="card stat-card">
                  <div className="card-body">
                    <p className="card-tit mb-0">Total Customers</p>
                    <p className="card-texts">{totalCustomer.length}</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-2 px-1 mb-2 mb-md-0">
                <div className="card stat-card">
                  <div className="card-body">
                    <p className="card-tit mb-0">Total Suppliers</p>
                    <p className="card-texts">{totalSupplier.length}</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-2 px-1 mb-2 mb-md-0">
                <div className="card stat-card">
                  <div className="card-body">
                    <p className="card-tit mb-0">Total Sales</p>
                    <p className="card-texts">{totalSale.length}</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-2 px-1 mb-2 mb-md-0">
                <div className="card stat-card">
                  <div className="card-body">
                    <p className="card-tit mb-0">Total Product</p>
                    <p className="card-texts">{totalProduct.length}</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-2 px-1 mb-2 mb-md-0">
                <div className="card stat-card">
                  <div className="card-body">
                    <p className="card-tit mb-0">Total User ID</p>
                    <p className="card-texts">{totalUser.length}</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-2 px-1 mb-2 mb-md-0">
                <div className="d-grid gap-2">
                  <a
                    href="https://rzp.io/l/5FmnimIQtK"
                    target="blank"
                    className="btn btn-sm btn-dark"
                  >
                    <FontAwesomeIcon icon={faDatabase} /> &nbsp; DB &amp; Domain
                  </a>
                </div>
                <p className="my-1" style={{ fontSize: 15 }}>
                  Expires on:{" "}
                  <b>{process.env.REACT_APP_EXPIRE || "03-03-2025"}</b>
                </p>
                <p className="m-0" style={{ fontSize: 12 }}>
                  This is appox time period before 25 days of expire!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 p-0">
            <div className="row m-1">
              <div className="col-12 px-0">
                <p className="point-header ms-2 mb-2">Daily Sales Report:</p>
                <div className="card stat-card">
                  <div className="card-header bg-white">
                    <span className="table-title">
                      <small>Showing: Last 30 Days Report</small>
                    </span>
                  </div>
                  <div className="card-body p-0">
                    <div className="productList">
                      <Report />
                    </div>
                  </div>
                  <div className="card-footer bg-white p-1">
                    <Link to={`/report`} className="text-decoration-none">
                      <p className="text-primary text-end my-1 me-2">
                        <small>See All Report</small>
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 px-0 mt-3">
                <p className="point-header ms-2 mb-2">Daily Emi Report:</p>
                <div className="card stat-card">
                  <div className="card-header bg-white">
                    <span className="table-title">
                      <small>Showing: Last 30 Days Report</small>
                    </span>
                  </div>
                  <div className="card-body p-0">
                    <div className="productList">
                      <EmiReport />
                    </div>
                  </div>
                  <div className="card-footer bg-white p-1">
                    <Link to={`/report`} className="text-decoration-none">
                      <p className="text-primary text-end my-1 me-2">
                        <small>See All Report</small>
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 px-0 mt-3">
                <p className="point-header ms-2 mb-2">All Recent Sales:</p>
                <div className="card stat-card">
                  <div className="card-header bg-white">
                    <span className="table-title">
                      <small>Showing: Recent Sales</small>
                    </span>
                  </div>
                  <div className="card-body p-0">
                    <div className="productList">
                      {loader ? (
                        <div className="text-center mt-4">
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          <p>
                            <small>Loading...</small>
                          </p>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-borderless table-sm">
                            <thead className="borderbottom">
                              <tr>
                                <td className="table-title ps-3">Customer</td>
                                <td className="table-title">Order-No</td>
                                <td className="table-title">Total</td>
                                <td className="table-title">Paid</td>
                                <td className="table-title">Balance</td>
                                <td className="table-title">Sale.Date</td>
                                <td className="table-title">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {totalSale.slice(0, 7).map((data, i) => {
                                return (
                                  <tr className="borderbottom p-0" key={i}>
                                    <td className="table-data ps-3">
                                      {data.customerName}
                                    </td>
                                    <td className="table-data">
                                      {data.orderNo}
                                    </td>
                                    <td className="table-data">
                                      ₹ {data.total}
                                    </td>
                                    <td className="table-data">
                                      ₹ {data.paid}
                                    </td>
                                    <td className="balance">
                                      ₹ {data.balance}
                                    </td>
                                    <td className="table-data">
                                      {new Date(data.createdAt).toDateString()}
                                    </td>
                                    <td className="table-data">
                                      <Link to={`/customer/${data.customerId}`}>
                                        <button className="showBillBtn">
                                          Show Customer
                                        </button>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card-footer bg-white p-1">
                    <Link to={`/sale`} className="text-decoration-none">
                      <p className="text-primary text-end my-1 me-2">
                        <small>See All Transactions</small>
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 px-1 my-1">
            <p className="point-header ms-2 mb-2">
              Low Quantity Stock's:{" "}
              <span className="badge bg-danger">Below Intimation Rate</span>
            </p>
            <div className="card stat-card">
              <div className="card-header bg-white p-0 my-2">
                <div className="row m-0 justify-content-between">
                  <div className="col-auto">
                    <p className="fw-bold m-1">
                      <small>Product</small>
                    </p>
                  </div>
                  <div className="col-auto">
                    <p className="fw-bold m-1">
                      <small>Quantity</small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="lowproductList">
                  {loader ? (
                    <div className="text-center mt-4">
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      <p>
                        <small>Loading...</small>
                      </p>
                    </div>
                  ) : (
                    <table className="table table-sm table-borderless m-2 mt-0 small">
                      <tbody>
                        {lowQuantityProduct.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td>{data.name}</td>
                              <td>{data.quantity}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <div className="card-footer bg-white p-1">
                <Link to={`/product`} className="text-decoration-none">
                  <p className="text-primary text-end my-1 me-2">
                    <small>See All Product</small>
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
