import React , {useState , useEffect} from 'react'
import Navbar from './Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';

export default function Setting() {

    const [areaData , setAreaData] = useState([]);
    const [cityData , setCityData] = useState([]);
   
    const [areaName , setAreaName] = useState("");
    const [cityName , setCityName] = useState("");
  
    const [loader,setLoader] = useState(true);

    const getAreaData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallarea`);
           setAreaData(res.data);
        }catch(e){
           toast.error("Connection Error");
        }
     }

     const getCityData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallcity`);
           setCityData(res.data);
        }catch(e){
           toast.error("Connection Error");
        }
     }

     const submitArea = async(e) => {
         try{
             e.preventDefault();
             const tempData = {
                 name : areaName
             }

             await toast.promise(
                axios.post(`${baseUrl}/post/newarea`,tempData),
               {
               pending: 'Loading in Progress',
               success: 'New Area Created',
               error: 'Process Rejected'
               }
           )
                setAreaName("");
                getAreaData();
         }catch(e){
             toast.error("Connection Error")
         }
     }

     const submitCity = async(e) => {
        try{
            e.preventDefault();
            const tempData = {
                name : cityName
            }

            await toast.promise(
                axios.post(`${baseUrl}/post/newcity`,tempData),
               {
               pending: 'Loading in Progress',
               success: 'New City Created',
               error: 'Process Rejected'
               }
           )

               setCityName("");
               getCityData();
        }catch(e){
            toast.error("Connection Error")
        }
    }

    const deleteArea = async(data) => {
        try{

            await toast.promise(
                axios.delete(`${baseUrl}/delete/deletearea/${data}`),
               {
               pending: 'Loading in Progress',
               success: 'Area Deleted',
               error: 'Process Rejected'
               }
           )

               getAreaData();
        }catch(e){
            toast.error("Connection Error")
        }
    }

    const deleteCity = async(data) => {
        try{
            await toast.promise(
                axios.delete(`${baseUrl}/delete/deletecity/${data}`),
               {
               pending: 'Loading in Progress',
               success: 'City Deleted',
               error: 'Process Rejected'
               }
           )
               getCityData();
        }catch(e){
            toast.error("Connection Error")
        }
    }

    

     useEffect(()=>{
        async function run(){
            await getAreaData();
            await getCityData();
            setLoader(false)
        }
        run();
     },[]);


    return (
        <div>
            <ToastContainer/>
            <Navbar/>
             <div className='mt-4 py-4'>
             <div className="row">
                <div className="col-12 col-md-4 px-1 my-1">
                <div className="card">
                <div className="card-header bg-white">
                        Add Area Information
                    </div>
                    <div className='card-body p-0'>
                       <div className='row'>
                               <div className='col-12 px-1'>
                                   <form onSubmit={submitArea}>
                                   <input className="form-control form-control-sm my-2" value={areaName} required onChange={(e)=>setAreaName(e.target.value)} placeholder="Enter Area Name"/>
                                   <button type='submit' className="btn btn-sm brand-Btn mb-3">Add Area</button>
                                   </form>
                               </div>
                       </div>
                    </div>
                </div> 
                <div className="card mt-3">
                <div className="card-header bg-white">
                        All Added Area's
                    </div>
                    {
                    loader ? 
                        <div className="text-center">
                            <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                            </div>
                            <p><small>Loading...</small></p>
                        </div>
                        :
                    <div className='card-body p-0 small'>
                        {
                            areaData.map((data,i)=>{
                                return(
                                    <div className='row justify-content-between my-2' key={i}>
                                    <div className='col'>
                                        <p className='my-0'>{data.name}</p>
                                    </div>
                                    <div className='col-auto'>
                                    <button className='btn btn-sm' onClick={()=>deleteArea(data._id)} >
                                    <FontAwesomeIcon icon={faTrash}/>
                                    </button>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                    }
                </div>                    
                </div>
                <div className="col-12 col-md-4 px-1 my-1">
                <div className="card">
                <div className="card-header bg-white">
                        Add City Information
                    </div>
                    <div className='card-body p-0'>
                    <div className='row'>
                               <div className='col-12 px-1'>
                                   <form onSubmit={submitCity}>
                                   <input className="form-control form-control-sm my-2" value={cityName} required onChange={(e)=>setCityName(e.target.value)} placeholder="Enter City Name"/>
                                   <button type="submit" className="btn btn-sm brand-Btn mb-3">Add City</button>
                                   </form>
                               </div>
                       </div>
                    </div>
                </div>   
                <div className="card mt-3">
                <div className="card-header bg-white">
                        All Added City's
                    </div>
                    {
                    loader ? 
                        <div className="text-center">
                            <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                            </div>
                            <p><small>Loading...</small></p>
                        </div>
                        :
                            <div className='card-body p-0 small'>
                            {
                                cityData.map((data,i)=>{
                                    return(
                                        <div className='row justify-content-between my-2' key={i}>
                                            <div className='col'>
                                                <p className='my-0'>{data.name}</p>
                                            </div>
                                            <div className='col-auto'>
                                            <button className='btn btn-sm' onClick={()=>deleteCity(data._id)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        }
                </div>                  
                </div>
            </div>
             </div>
        </div>
    )
}
