import React  from 'react'
import Navbar from './Navbar';

import { ToastContainer } from 'react-toastify';
import SaleReport from "./DashboardReport";
import EmiReport from "./Emichart";
  


export default function Report() {


    return (
       <div>
           <ToastContainer/>
           <Navbar/>
          <div className='mt-4 py-4'>
           <div className="row">
           <div className='col-12 col-md-6 mt-3'>
                    <p className='point-header ms-2 mb-2'>Daily Sales Report:</p>
                    <div className="card stat-card">
                        <div className="card-header bg-white">
                        <span className='table-title'><small>Showing: Last 30 Days Report</small></span>
                            </div>
                            <div className='card-body p-0'>
                            <div className='productList'>
                               <SaleReport/>
                            </div>
                            </div>
                            <div className="card-footer bg-white p-1">
                            </div>
                        </div>  
                  </div>
                  <div className='col-12 col-md-6 mt-3'>
                    <p className='point-header ms-2 mb-2'>Daily Emi Report:</p>
                    <div className="card stat-card">
                        <div className="card-header bg-white">
                        <span className='table-title'><small>Showing: Last 30 Days Report</small></span>
                            </div>
                            <div className='card-body p-0'>
                            <div className='productList'>
                                    <EmiReport/>
                            </div>
                            </div>
                            <div className="card-footer bg-white p-1">
                            </div>
                        </div>  
                  </div>
           </div>
          </div>
       </div>
    )
}
