import React , {useState , useEffect} from 'react'
import "../Login.css"
import "../App.css"
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';

export default function Login() {


    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [loader,setLoader] = useState(false);


    const onFinish = async() => {
        try{
            setLoader(true);
            const data = {
                username : username,
                password : password
            }
            const res = await axios.post(`${baseUrl}/admin/postadminlogin`,data);
            const resData = res.data;

            if (resData.status === "ok") {
                localStorage.setItem('Token-Nelvanam', resData.user)
                window.location.replace("/dashboard");
            } else {
                toast.error("Username or Password may Incorrect");
                setLoader(false);
            }
        }catch(e){
             toast.error("You'r Not Allowed");
            setLoader(false);
        }
     };


      const checkUser = () =>{
       try{
            const userData = localStorage.getItem("Token-Nelvanam");
            if(userData != null){
                window.location.replace("/dashboard");
            }
       }catch(e){
           console.log(e)
       }
    }


    useEffect(()=>{
        checkUser();
    },[])

    return (
            <div className="container">
                <ToastContainer/>
            <div className="row formss">
                <div className="col-12 col-md-4 mx-auto">
                    <div className="card bg-white p-3" id="forms">
                        <div className="card-body">
                                <div className="text-center">
                                    <img src="https://www.nelvanam.com/img/logo.png" width="150" className="img-fluid" alt=""/>
                                </div>
                                <h5 className="my-4 text-center">Login to Nelvanam</h5>
                                <div className="form-group mb-3">
                                <input type="text" className="form-control login-control" placeholder="Username" onChange={(e)=>setUsername(e.target.value)} required/>
                                </div>
                                <div className="form-group mb-4">
                                <input type="password" className="form-control login-control" placeholder="Password" onChange={(e)=>setPassword(e.target.value)} required/>
                                </div>
                                <div className="d-grid gap-2">
                                <button type="button" onClick={onFinish} className="btn login-btn py-2">
                                {
                                    !loader ?
                                            "Login to dashboard"
                                            : 
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                                </div>
                                            }
                                </button>
                                </div>
                        </div>
                    </div><br/>
                    <div className="row-auto justify-content-center">
                        <div className="col-10 col-md-9 mx-auto">
                        <p className="small text-secondary text-center mb-2">All rights reserved and data source are handle by only admin and their worker related to admin role.</p>
                        <p className="small text-dark text-center">Copyright &copy; Since 2020 Nelvanam - V1.0</p>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    )
}
