/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  const logout = () => {
    localStorage.removeItem("Token-Nelvanam");
    window.location.replace("/");
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top py-0"
      id="navbar"
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/dashboard">
          <img
            src="https://qudo-creations.s3.ap-south-1.amazonaws.com/other-brand-assets/nelvanam.png"
            alt=""
            width="80"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" to="/dashboard">
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/billing">
                Billing
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/emibilling">
                EMI-Billing
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/customer">
                Customer
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/product">
                Product
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/expense">
                Expense
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/sale">
                All Sales
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/trader">
                Supplier
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/report">
                Report
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/setting">
                Setting
              </Link>
            </li>
          </ul>
          <form className="d-flex">
            <button className="showBillBtn" type="button" onClick={logout}>
              <FontAwesomeIcon icon={faSignOutAlt} /> &nbsp; Logout
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
}
