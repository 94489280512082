import React , {useEffect , useState} from 'react'
import axios from "axios";
import baseUrl from '../utils/Url';
import _  from "lodash";

import { ToastContainer, toast } from 'react-toastify';
import { Line } from 'react-chartjs-2';
  


export default function DashboardReport() {


    const [label , setChartLabel] = useState([]);
    const [data2 , setChartData2] = useState([]);

    const getAllData = async() => {
        try{
            const saleRes = await axios.get(`${baseUrl}/get/getallsale`);
            

            const saledata = saleRes.data.reverse();

            const yearPlusMonth = [];
            const totalSaleOnDaily = [];

            for(var i of saledata){
                const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                yearPlusMonth.push({ 
                    date : months[(new Date(i.createdAt)).getMonth()] + " " + ((new Date(i.createdAt)).getDate()),
                    year : (new Date(i.createdAt)).getFullYear()  
                });
                totalSaleOnDaily.push({ 
                    date : ((new Date(i.createdAt)).getDate())
                           + " " + 
                           months[(new Date(i.createdAt)).getMonth()]
                           + " " + 
                            (new Date(i.createdAt)).getFullYear(),
                    paid : i.paid 
                })
            }


            const loadingToCount = yearPlusMonth.map((data)=>{
                return data.date;
            })

            const saleFilter = _(totalSaleOnDaily).groupBy('date')
                                .map((product, id) => ({
                                    dailyName : id,
                                    dailySale : _.sumBy(product, 'paid')
                                })).value()

               
                                
               

            const countTheRegistration = _.countBy(loadingToCount);

            const finalLabel = [];
            for(var j in countTheRegistration){
                finalLabel.push(j);
            } 

            const saleresult = [];
            for(var k in saleFilter){
                saleresult.push(saleFilter[k].dailySale);
            } 
            
            const len = (saleresult.length > 31) ? (saleresult.length - 31) : 0;

            setChartData2(saleresult.slice(len))
            setChartLabel(finalLabel.slice(len))



        }catch(e){
            toast.error("Connection Error")
        }
    }



    
    useEffect(()=>{
        getAllData();
    },[])


    return (
        <>
        <ToastContainer/>
        <Line
        datasetIdKey='id'
        options={{ maintainAspectRatio: false }}
        data={{
            labels: label,
            datasets: [
            {
                id: 1,
                label: 'Sales Amount',
                data: data2,
                fill: false,
                borderColor: '#528FF0',
                borderWidth : 1.2
            }
            ],
        }}
        />
        </>
    )
}
