import React , {useEffect , useState} from 'react'
import Navbar from './Navbar';
import axios from "axios";
import baseUrl from '../utils/Url';
import { ToastContainer, toast } from 'react-toastify';


export default function Expense() {

    const [saleData , setExpenseData] = useState([]);
    const [filterSale , setFilterSale] = useState([]);
    const [loader,setLoader] = useState(true);
    const [userData , setUserData] = useState([]);
    const [expense , setExpense] = useState(0);

    const [username , setUsername] = useState("");
    const [password , setPassword] = useState("");
    const [purpose , setPurpose] = useState("");

    const getExpenseData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallexpense`);
           setExpenseData(res.data);
           setFilterSale(res.data);
           setLoader(false);
        }catch(e){
           toast.error("Connection Error");
        }
     }

     const getUserData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getalluser`);
           setUserData(res.data);
        }catch(e){
           toast.error("Connection Error");
        }
     }

     const submitExpense = async() => {
        try{
            const tempData = {
                spend_by : username,
                amount : expense,
                purpose : purpose
            }

           await toast.promise(
            axios.post(`${baseUrl}/post/newexpense`,tempData),
           {
           pending: 'Loading in Progress',
           success: 'Expense Added!',
           error: 'Process Rejected'
           }
       )
       getExpenseData();
       setUsername("");
       setPassword("");
       setPurpose("");
       setLoader(false);
        }catch(e){
           toast.error("Connection Error");
        }
     }

     const onFinish = async(e) => {
      try{
        e.preventDefault();
          setLoader(true);
          const data = {
              username : username,
              password : password
          }
          const res = await axios.post(`${baseUrl}/admin/postadminlogin`,data);
          const resData = res.data;

          if (resData.status === "ok") {
            submitExpense();
          } else {
              toast.error("Username or Password may Incorrect");
              setLoader(false);
          }
      }catch(e){
           toast.error("Please enter your correct username and password!");
          setLoader(false);
      }
   };

     const preventMinus = (e) => { 
        if((e.target.value).includes("-")){
            setExpense(Math.abs(e.target.value))
        }
      };

     useEffect(()=>{
        getUserData();
     },[])

     useEffect(()=>{
        getExpenseData();
     },[])

    return (
        <>
        <ToastContainer/>
        <Navbar/>
            <div className='mt-4 py-4'>
        <div className='row justify-content-between align-items-center mt-4 p-0'>
            <div className='col-auto'>
                <h4 style={{fontWeight : 600,margin : "auto"}}>All Expense's - {saleData.length}</h4>
            </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3 px-1">
                <div className='card stat-card'>
                  <div className='card-body px-2'>
                     <form onSubmit={onFinish} autocomplete="off">
                     <h5 className='fw-bold'>Create Expense</h5>
                      <p className='small m-0 mb-2' style={{fontWeight : 600}}>Select Username for this expense *</p>
                        <select className="form-select form-select-sm text-secondary mb-3"
                        onChange={(e)=>setUsername(e.target.value)}
                        >
                                <option selected value="Not Selected">No User Selected</option>
                                {
                                    userData.map((data,i)=>{
                                            return(
                                                <option value={data.username} key={i}>{data.username}</option>
                                            )
                                    })
                                }
                        </select>
                        <p className='small m-0 mb-2' style={{fontWeight : 600}}>Enter the amount *</p>
                        <input 
                        className="form-control form-control-sm mb-3" 
                        placeholder="Enter amount" 
                        value={expense} 
                        type="number"
                        onKeyUp={preventMinus}
                        onChange={(e)=>setExpense(e.target.value === "" || e.target.value === "-" ? 0 : e.target.value)} 
                        onPaste={(e)=>{
                        e.preventDefault()
                        return false;
                        }}
                        />
                        <p className='small m-0 mb-2' style={{fontWeight : 600}}>Enter the Password *</p>
                        <input type="password" className="form-control form-control-sm mb-3" placeholder="Password" onChange={(e)=>setPassword(e.target.value)} required/>
                        <p className='small m-0 mb-2' style={{fontWeight : 600}}>Enter the Purpose</p>
                        <textarea rows={4} className="form-control form-control-sm mb-3" placeholder="Purpose of amount taken" onChange={(e)=>setPurpose(e.target.value)}/>
                        <button className="btn btn-sm brand-Btn" type='submit' disabled={expense > 0 ? false : true}>Create Expense</button>
                     </form>
                  </div>
                </div>
          </div>
          <div className="col-12 col-md-9 px-1">
            <div className='card stat-card'>
              <div className='card-body'>
                  {
                  loader ? 
                    <div className="text-center">
                        <div className="spinner-border spinner-border-sm" role="status">
                                  <span className="visually-hidden">Loading...</span>
                        </div>
                        <p><small>Loading...</small></p>
                    </div>
                    :
                    <div className="table-responsive">
                    <table className="table table-borderless table-sm">
                        <thead className='borderbottom'>
                          <tr>
                            <td className="table-title">User</td>
                            <td className="table-title">Expense</td>
                            <td className="table-title">Purpose</td>
                            <td className="table-title">Date</td>
                          </tr>
                        </thead>
                        <tbody>
                        {
                                filterSale.map((data,i)=>{
                                  return(
                                    <tr className='borderbottom p-0' key={i}>
                                      <td className='table-data'>{data.spend_by}</td>
                                      <td className='balance'>₹ {data.amount}</td>
                                      <td className='table-data'>{data.purpose}</td>
                                      <td className='table-data'>{new Date(data.createdAt).toDateString()}</td>
                                    </tr>
                                  )
                                })
                              }
                        </tbody>
                      </table>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
    </div>
        </>
    )
}
