import React, {useState , useEffect} from 'react';
import Navbar from './Navbar'
import axios from "axios";
import baseUrl from '../utils/Url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap, faUserPlus} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
 
export default function Customer() {


    
    const [customerData , setCustomerData] = useState([]);
    const [filterCustomer , setFilterCustomer] = useState([]);
    const [confirmpassword , setConfirmPassword] = useState("");
    const [currentUser , setCurrentUser] = useState({});

    const [filterMembership,setMembershipFilter] = useState("");
    const [filterArea,setAreaFilter] = useState("");
    const [filterCity,setCityFilter] = useState("");
    const [filterEmi,setEmiFilter] = useState("");

    const [loader,setLoader] = useState(true);

    const [areaData , setAreaData] = useState([]);
    const [cityData , setCityData] = useState([]);

    const getCustomerData = async() => {
        try{
           const res = await axios.get(`${baseUrl}/get/getallcustomer`);
           setCustomerData(res.data);
           setFilterCustomer(res.data);
           setLoader(false);
        }catch(e){
           toast.error("Connection Error");
        }
     }


     const deleteCustomer = async(userData) => {
        try{
         await toast.promise(
            axios.delete(`${baseUrl}/delete/deletecustomer/${userData}`),
           {
           pending: 'Loading in Progress',
           success: 'Customer Deleted',
           error: 'Deletion Rejection'
           }
       )
                setConfirmPassword("");
                getCustomerData();
           
         }catch(e){
            toast.error("Connection Error");
         }
     }


     const getAreaData = async() => {
      try{
         const res = await axios.get(`${baseUrl}/get/getallarea`);
         setAreaData(res.data);
      }catch(e){
            toast.error("Connection Error");
      }
   }

   const getCityData = async() => {
      try{
         const res = await axios.get(`${baseUrl}/get/getallcity`);
         setCityData(res.data);
      }catch(e){
            toast.error("Connection Error");
      }
   }


     const searchCustomer = (val) => {
        const filteredData = customerData.filter(i => i.name.toLowerCase().includes(val.toLowerCase()) ||
                                                      i.whatsapp.toLowerCase().includes(val.toLowerCase()) ||
                                                      i.phone2.toLowerCase().includes(val.toLowerCase()) ||
                                                      i.phone3.toLowerCase().includes(val.toLowerCase()) ||
                                                      i.phone4.toLowerCase().includes(val.toLowerCase()) ||
                                                      i.city.toLowerCase().includes(val.toLowerCase()) ||
                                                      i.membership.toLowerCase().includes(val.toLowerCase()) ||
                                                      i.area.toLowerCase().includes(val.toLowerCase()));
         setFilterCustomer(filteredData);
     }

     const searchViaMembership = (val) => {
        setMembershipFilter(val);
     }

     const searchViaArea = (val) => {
      setAreaFilter(val);
     }

     const searchViaCity = (val) => {
      setCityFilter(val);
     }

     const searchViaEmiCustomer = (val) => {
       setEmiFilter(val);
     }


     const filterall = () =>{
       var dataTemp;
       setFilterCustomer([]);

      dataTemp = customerData.filter(i => i.membership.toLowerCase().includes(filterMembership.toLowerCase()) &&
                                                      i.area.toLowerCase().includes(filterArea.toLowerCase()) &&
                                                      i.city.toLowerCase().includes(filterCity.toLowerCase()) &&
                                                      (filterEmi === "" ? i.balance < 1000000000 : i.balance > filterEmi));
       setFilterCustomer(dataTemp);
     }

    const clearFilter = () => {
     setFilterCustomer(customerData) 
    }
    
    
      useEffect(()=>{
        getCustomerData();
      },[])

      useEffect(()=>{
        getCityData();
      },[])

      useEffect(()=>{
        getAreaData();
      },[])
      


    return (
        <div>
        <ToastContainer/>
        <Navbar/>
            <div className='mt-4 py-4'>
          <div className='row justify-content-between align-items-center mt-4 p-0'>
              <div className='col-auto'>
                  <h4 style={{fontWeight : 600,margin : "auto"}}>All Customer - {customerData.length}</h4>
              </div>
              <div className='col-auto'>
              <Link to="/addcustomer" className="btn btn-sm brand-Btn"><FontAwesomeIcon icon={faUserPlus} /> &nbsp; Add New Customer</Link>
              </div>
          </div>
        <div className="row">
          <div className="col-12 col-md-2 px-1">
                 <div className='card stat-card'>
                    <div className='card-body p-2'>
                    <input className="form-control form-control-sm mb-2" onChange={(e)=>searchCustomer(e.target.value)} type="search" placeholder="Search Customer" aria-label="Search"/>
                 <p className='px-2'><small>Use Customer Name or Phone Number to search.</small></p>
                 <div className='row'>
                 <div className='col-12 px-1'>
                 <button className="showBillBtn mb-3" type="button" onClick={clearFilter}>Clear All Filter</button>
                 <p className='small m-0 mb-2' style={{fontWeight : 600}}>Filter Membership</p>
                        <select className="form-select form-select-sm text-secondary mb-3" onChange={(e)=>searchViaMembership(e.target.value)}>
                          <option selected value="">No Membership Selected</option>
                          <option value="Diamond">Diamond</option>
                          <option value="Gold">Gold</option>
                          <option value="Silver">Silver</option>
                          <option value="Bronze">Bronze</option>
                          </select>
                  </div>
                 <div className='col-12 px-1'>
                    <p className='small m-0 mb-2' style={{fontWeight : 600}}>Filter Area</p>
                    <select className="form-select form-select-sm text-secondary mb-3"
                    onChange={(e)=>searchViaArea(e.target.value)}>
                            <option selected value="">No Area Selected</option>
                            {
                                  areaData.map((data,i)=>{
                                        return(
                                            <option value={data.name} key={i}>{data.name}</option>
                                        )
                                  })
                            }
                            </select>
                    </div>
                    <div className='col-12 px-1'>
                    <p className='small m-0 mb-2' style={{fontWeight : 600}}>Filter City</p>
                    <select className="form-select form-select-sm text-secondary mb-3"
                    onChange={(e)=>searchViaCity(e.target.value)}>
                            <option selected value="">No City Selected</option>
                            {
                                  cityData.map((data,i)=>{
                                        return(
                                            <option value={data.name} key={i}>{data.name}</option>
                                        )
                                  })
                            }
                            </select>
                    </div>
                    <div className='col-12 px-1'>
                    <p className='small m-0 mb-2' style={{fontWeight : 600}}>Filter Customer</p>
                    <select className="form-select form-select-sm text-secondary mb-3"
                    onChange={(e)=>searchViaEmiCustomer(e.target.value)}>
                            <option selected value="">All Customers</option>
                            <option value="0">Only Emi Customer</option>
                            </select>
                    </div>
                    <button className="btn btn-sm brand-Btn" onClick={filterall}>Apply Filter</button>
                 </div>
                    </div>
                 </div>
          </div>
          <div className="col-12 col-md-10 px-1">
            <div className="card stat-card">
                <div className="card-body">
                {
              loader ? 
                <div className="text-center">
                    <div className="spinner-border spinner-border-sm" role="status">
                              <span className="visually-hidden">Loading...</span>
                    </div>
                    <p><small>Loading...</small></p>
                </div>
                :
                  <div className="table-responsive">
                  <table className="table table-borderless table-sm">
                      <thead className='borderbottom'>
                        <tr>
                          <td className="table-title">Customer</td>
                          <td className="table-title">Phone</td>
                          <td className='table-title'>Area</td>
                          <td className="table-title">Map</td>
                          <td className="table-title">Balance</td>
                          <td className="table-title">Membership</td>
                          <td className="table-title">Delete</td>
                          <td className="table-title">View</td>
                        </tr>
                      </thead>
                      <tbody>
                      {
                              filterCustomer.map((data,i)=>{
                                return(
                                  <tr className='borderbottom p-0' key={i}>
                                    <td className='table-data'>{data.name}</td>
                                    <td className='table-data'>{data.whatsapp}</td>
                                    <td className='table-data'>{data.area}</td>
                                    <td className='table-data'><a href={data.maplink} target="blank"><FontAwesomeIcon icon={faMap} /></a></td>
                                    <td className='table-data'><p className='balance m-0'>₹ {data.balance}</p></td>
                                    <td className='table-data'>{data.membership}</td>
                                    <td className='table-data'>
                                        <button className='showBillBtn' onClick={()=>setCurrentUser(data)} data-bs-toggle="modal" data-bs-target="#deletecustomer">
                                                  Delete Customer
                                      </button>
                                      </td>
                                    <td className='table-data'>
                                        <Link to={`/customer/${data._id}`}>
                                            <button className='showBillBtn'>
                                                    Show Customer
                                        </button>
                                        </Link>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                      </tbody>
                    </table>
                  </div>
              }
                </div>
            </div>
          </div>
        </div>
    </div>
  <div className="modal fade" id="deletecustomer" data-bs-backdrop="static" aria-labelledby="updateLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
     
     <div className="modal-content">
        <div className="modal-header">
          <p className="modal-title" id="updateLabel">Delete User Details</p>
        </div>
        <div className="modal-body">
          <div className='row'>
          <div className='col-12 px-1'>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>Enter this name below: <b>{currentUser.name}</b></p>
            <p className='small m-0 mb-2' style={{fontWeight : 600}}>{currentUser._id}</p>
            </div>
          <div className='col-12 px-1'>
                  <input className="form-control form-control-sm mb-3" 
                  value={confirmpassword} onChange={(e)=>setConfirmPassword(e.target.value)} 
                  placeholder="Enter Customer Name"/>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-sm btnClose" onClick={()=>setCurrentUser({})} data-bs-dismiss="modal">Cancel</button>
          <button type="submit" onClick={()=> deleteCustomer(currentUser._id)} disabled={confirmpassword === currentUser.name ? false : true} className="btn btn-sm brand-Btn" data-bs-dismiss="modal">Delete</button>
        </div>
      </div>
    </div>
  </div>
        </div>
    )
}
